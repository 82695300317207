import { Floor1 } from "./Floor1";
import { Floor2 } from "./Floor2";
import { Floor1CCC } from "./Floor1CCC";
import { Floor2CCC } from "./Floor2CCC";
import Floor1MintTowerBuildingMain from "assets/pages/Dashboard/FloorImage/Floor1MintTowerBuildingMain.svg";
import FloorMMintTowerBuildingMain from "assets/pages/Dashboard/FloorImage/FloorMMintTowerBuildingMain.svg";
import Floor2MintTowerBuildingMain from "assets/pages/Dashboard/FloorImage/Floor2MintTowerBuildingMain.svg";
import Floor3MintTowerBuildingMain from "assets/pages/Dashboard/FloorImage/Floor3MintTowerBuildingMain.svg";
import Floor4MintTowerBuildingMain from "assets/pages/Dashboard/FloorImage/Floor4MintTowerBuildingMain.svg";
import Floor510MintTowerBuildingMain from "assets/pages/Dashboard/FloorImage/Floor510MintTowerBuildingMain.svg";
import Floor1RDBuildingMain from "assets/pages/Dashboard/FloorImage/Daikin_R&D_Fl.1.png";
import Floor2RDBuildingMain from "assets/pages/Dashboard/FloorImage/Daikin_R&D_Fl.2.png";
import Floor3RDBuildingMain from "assets/pages/Dashboard/FloorImage/Daikin_R&D_Fl.3.png";
import RoofBuildingMain from "assets/pages/Dashboard/FloorImage/Daikin_R&D_Roof.svg";
import LotusGofreshFloorPlan from "assets/pages/Dashboard/FloorImage/LotusGofreshFloorPlan.svg";
import { FloorModelContainer, ImageContainer, ScaleImage, Image } from "./Components";
import { DeviceIcon, TempZoneAc } from "./DeviceIcon";
import { DeviceProps } from "MockData/DeviceData";
import HeatScale from "assets/pages/Dashboard/HeatScale.svg";
import ShellFloorPlan from "assets/pages/FloorUsage/FloorPlan/ShellFloorPlan.svg";
import Draggable from 'react-draggable'
import { useState } from "react";
import styled from "styled-components";
import { updateDevice } from "services/api.service";

const IconContainer = styled.div<any>`
  position: absolute;
  width: fit-content;
  height: fit-content;
  top: ${(props) => props.y}%;
  left: ${(props) => props.x}%;
`;


const FloorMap: { [hotel_code: string]: { [floorName: string]: any } } = {
  BGM: {
    F1: <Floor1 />,
    F2: <Floor2 />,
  },
  DAIC: {
    F1: <Floor1CCC />,
    F2: <Floor2CCC />,
  },
  MTW: {
    F1: <Image src={Floor1MintTowerBuildingMain} />,
    M: <Image src={FloorMMintTowerBuildingMain} />,
    F2: <Image src={Floor2MintTowerBuildingMain} />,
    F3: <Image src={Floor3MintTowerBuildingMain} />,
    F4: <Image src={Floor4MintTowerBuildingMain} />,
    F5: <Image src={Floor510MintTowerBuildingMain} />,
    F6: <Image src={Floor510MintTowerBuildingMain} />,
    F7: <Image src={Floor510MintTowerBuildingMain} />,
    F8: <Image src={Floor510MintTowerBuildingMain} />,
    F9: <Image src={Floor510MintTowerBuildingMain} />,
    F10: <Image src={Floor510MintTowerBuildingMain} />,
  },
  SHE: {
    F1: <Image src={ShellFloorPlan} />,
  },
  LTLS: {
    F1: <Image src={LotusGofreshFloorPlan} />,
  },
  DAIR: {
    F1: <Image src={Floor1RDBuildingMain} />,
    F2: <Image src={Floor2RDBuildingMain} />,
    F3: <Image src={Floor3RDBuildingMain} />,
    Roof: <Image src={RoofBuildingMain} />,
  },
};

export const FloorModel = ({ deviceList, selectedTab, handleOpenModal, visibleDeviceObject, hotel_code, allIotStatusFb }: any) => {
  const [disabledPosition, setDisabledPosition] = useState(true);
  const onDrag = (e: any, data: any) => {
    if (data.x < 0 || data.y < 0) {
      setDisabledPosition(true);
    } else {
      setDisabledPosition(false);
    }
  }

  const onStart = () => {
  };

  const onStop = (e: any, data: any, device_id: string) => {
    updateDeviceLocation({
      x: data.x / 8.99,
      y: data.y / 5.99
    }, device_id)
  };

  const updateDeviceLocation = async (data: { x: number, y: number }, device_id: string) => {
    try {
      await updateDevice({
        'device_id': device_id,
        "ui_location": data.x.toFixed(1) + ',' + data.y.toFixed(1)
      })
    }
    catch (err) {
      console.log(err)
    }
  }

  const dragHandlers = { onStart: onStart };

  return (
    <FloorModelContainer>
      <ImageContainer>
        {["DAIC", "BGM", "MTW", "SHE", "LTLS", "DAIR"].includes(hotel_code) && selectedTab !== "B" && (
          <>
            <div>
              {hotel_code && FloorMap[hotel_code][selectedTab]}
              {deviceList &&
                deviceList.map((item: DeviceProps, index: number) => {
                  return (
                    <>
                      <Draggable
                        key={item.device_id}
                        disabled={disabledPosition}
                        onDrag={onDrag}
                        onStop={(e: any, data: any) => onStop(e, data, item.device_id)}
                        {...dragHandlers}
                      >
                        <IconContainer x={item.ui_location[0]} y={item.ui_location[1]} key={item.device_id + index}>
                          <DeviceIcon
                            deviceId={item.device_id}
                            agentId={item.agent_id}
                            onClick={() => handleOpenModal(item)}
                            x={0}
                            y={0}
                            showTempZone={true}
                            firebaseDeviceData={allIotStatusFb && allIotStatusFb[item.device_id]}
                            hotel_code={hotel_code}
                          />
                          {!disabledPosition && <div className="absolute bottom-[-10px] w-4 h-4" style={{ background: 'red' }} />}
                        </IconContainer>

                      </Draggable>
                      {hotel_code === 'BGM' && item.agent_id === 'bac0hvac' &&
                        <TempZoneAc
                          firebaseDeviceData={allIotStatusFb && allIotStatusFb[item.device_id]}
                          x={item.ui_location[0]}
                          y={item.ui_location[1]}
                          deviceId={item.device_id}
                        />}
                    </>
                  )
                })}
            </div>
            {hotel_code !== "SHE" && <ScaleImage src={HeatScale} />}
          </>
        )}
      </ImageContainer>
    </FloorModelContainer>
  );
};
