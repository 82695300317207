import { BlockHeader, HeaderContainer, TitleWrap } from "./Components";
import { ChangeViewButton } from "components/Common/ChangeViewButton/ChangeViewButton";
import { DeviceFilter } from "./DeviceFilter";

const RoomSectionHeader = ({
  setVisibleDeviceObject,
  selectedDeviceList,
  setSelectedDeviceList,
  selectedTab,
  buildingName,
  selectedView,
  setSelectedView,
  deviceList,
}: any) => {
  const handleSelectDevice = (agentId: string) => {
    setSelectedDeviceList((prev: any) => {
      let tempPrev = { ...prev };
      tempPrev = {
        ...tempPrev,
        [agentId]: {
          ...tempPrev[agentId],
          isOpened: !tempPrev[agentId]["isOpened"],
        },
      };
      return tempPrev;
    });
    setVisibleDeviceObject((prev: any) => {
      let tempPrev = { ...prev };
      tempPrev[agentId] = !tempPrev[agentId];
      return tempPrev;
    });
  };

  const renameBuildingName = (buildingName: string) => {
    switch (buildingName) {
      case "Daikin R&D":
        return "Research and Development Center 2";
      default:
        return buildingName;
    }
  };

  return (
    <TitleWrap>
      <HeaderContainer>
        <BlockHeader>
          {renameBuildingName(buildingName) + " : " + selectedTab}
        </BlockHeader>
        <DeviceFilter
          selectedDeviceList={selectedDeviceList}
          handleSelectDevice={handleSelectDevice}
          deviceList={deviceList}
        />
      </HeaderContainer>
      {/* <ChangeViewButton
        selectedView={selectedView}
        setSelectedView={setSelectedView}
      /> */}
    </TitleWrap>
  );
};

export default RoomSectionHeader;
