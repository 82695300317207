import { useState } from "react";
import styled from "styled-components";
import ProfileCircle from "assets/common/icon/AvataAccount.svg";
import DropdownIcon from "assets/common/icon/dropdown.svg";
import { useSpring, animated } from "react-spring";
import { useHistory } from "react-router-dom";
import { useBuildingContext } from "layouts/BuildingProvider";
import { useUserContext } from "layouts/UserProvider";

const Container = styled.div`
  height: 100%;
  display: flex;
  justify-content: space-between;
  margin: 0px 10px;
`;

const Block = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 0px 0px 15px;
  cursor: pointer;
`;

const ProfileSym = styled.div`
  width: 32px;
  height: 32px;
  background: url(${ProfileCircle}) center no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ProfileName = styled.p`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  margin: 0px;
  user-select: none;
  :hover {
    opacity: 0.8;
  }
`;

const DropdownImage = styled.img`
  width: 8px;
  height: 8px;
  margin-left: 7px;
  margin-top: 2px;
  user-select: none;
`;

const DropdownContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  top: 50px;
  right: 0px;
  width: 150px;
  height: fit-content;
  padding: 5px 15px 10px 15px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
`;

const DropdownRow = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  padding: 10px 0px;
  user-select: none;
  :hover {
    opacity: 0.8;
  }
`;

const TransparentBg = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0px;
  top: 0px;
`;

const AnimatedDropdownContainer = animated(DropdownContainer);

type ProfleDropdownProps = {
  open: boolean;
};

const ProfileDropdown = ({ open }: ProfleDropdownProps) => {
  let history = useHistory();
  const dropdownProps = useSpring({
    opacity: open ? "1" : "0",
    display: open ? "block" : "none",
  });

  const handleLogout = () => {
    const targetDate = localStorage.getItem("targetDate"); // Save the accessToken

    // Clear everything from localStorage
    localStorage.clear();

    // Restore the targetDate
    if (targetDate) {
      localStorage.setItem("targetDate", targetDate);
    }

    history.push("/login");
  };

  return (
    <>
      <AnimatedDropdownContainer style={dropdownProps}>
        {/* ---------- TODO ---------- */}
        {/* <DropdownRow onClick={() => (window.location.href = "/admin/myprofile")}>My profile</DropdownRow> */}
        {/* ---------- TODO ---------- */}
        <DropdownRow onClick={() => handleLogout()}>Logout</DropdownRow>
      </AnimatedDropdownContainer>
    </>
  );
};

const RoleMapper: any = {
  md: "admin",
  front: "viewer",
};

const Profile = () => {
  const { buildingName } = useBuildingContext();
  const { role } = useUserContext();
  const [open, setOpen] = useState(false);
  return (
    <>
      {open && <TransparentBg onClick={() => setOpen(!open)} />}
      <Container onClick={() => setOpen(!open)}>
        <Block>
          <ProfileSym />
        </Block>
        <Block>
          <ProfileName>
            <span>{buildingName}</span>
            <small style={{ marginTop: "-2px" }}>{RoleMapper[role]}</small>
          </ProfileName>
          <DropdownImage src={DropdownIcon} />
          <ProfileDropdown open={open} />
        </Block>
      </Container>
    </>
  );
};

export default Profile;
