import styled from "styled-components";
import {
  BlockContainer,
  BlockHeader,
  GraphContainer,
} from "components/Common/components";
import { Row, Col } from "antd";
import { PieChart } from "components/Common/Graph/PieChart";
import { LoadingPage } from "components/Common/LoadingPage/LoadingPage";
import { MyResponsiveBar } from "./EnergyBaseline";
import { useBuildingContext } from "layouts/BuildingProvider";
import { useEffect, useState } from "react";

const Container = styled(BlockContainer)`
  background-color: white;
  display: flex;
  flex-direction: column;
  padding-top: 15px;
  padding-bottom: 10px;
  filter: drop-shadow(0px 2px 10px rgba(171, 183, 196, 0.2));
  height: 235px;
  justify-content: space-between;
`;

export const EnergyConsumption = ({ data, title, graphType }: any) => {
  const { buildingCode } = useBuildingContext();

  const [transformedData, setTransformedData] = useState<any>([]);
  useEffect(() => {
    if (buildingCode === "DAIC") {
      const acLoad = data?.find((item: any) => item.id === "AC Load");
      const totalLoad = data?.find((item: any) => item.id === "Total Load");
      const otherLoadValue = (
        parseFloat(totalLoad?.value) - parseFloat(acLoad?.value)
      ).toFixed(2);

      setTransformedData([
        acLoad,
        {
          id: "Other",
          value: otherLoadValue,
          label: "Other",
        },
      ]);
    }
  }, [data, buildingCode]);
  const formattedData = data?.data?.map((entry: any) => {
    let formattedEntry: any = {};
    for (const [key, value] of Object.entries(entry)) {
      if (key !== "timestamp" && typeof value === "number") {
        formattedEntry[key] = parseFloat((value as number).toFixed(1));
      } else {
        formattedEntry[key] = value;
      }
    }
    return formattedEntry;
  });

  return (
    <>
      <Container>
        <Row>
          <Col sm={12} lg={24}>
            <BlockHeader style={{ marginBottom: "25px" }} sm>
              {title}
            </BlockHeader>
          </Col>
        </Row>
        <Row>
          <GraphContainer style={{ height: " 160px" }}>
            {graphType === "pie" && data && (
              <PieChart
                data={buildingCode === "DAIC" ? transformedData : data}
                unit={"kWh"}
              />
            )}
            {graphType === "bar" && data && (
              <MyResponsiveBar
                data={formattedData}
                keys={data.keys}
                indexBy={data.indexBy}
                legend={data.legend}
              />
            )}
            {data === undefined && <LoadingPage height={"100%"} />}
            {data === null && (
              <p
                style={{
                  width: "100%",
                  textAlign: "center",
                  marginTop: "50px",
                  color: "#0E7EE4",
                }}
              >
                No matching data found
              </p>
            )}
          </GraphContainer>
        </Row>
      </Container>
    </>
  );
};
