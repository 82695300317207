import AcIconOn from "assets/pages/Dashboard/DeviceIcon/AcIconOn.svg";
import NetatmoOn from "assets/pages/Dashboard/DeviceIcon/NetatmoOn.svg";
import CCTV from "assets/pages/Dashboard/DeviceIcon/CCTV.svg";
import BlindOn from "assets/pages/Dashboard/DeviceIcon/BlindOn.svg";
import FreshAirOn from "assets/pages/Dashboard/DeviceIcon/FreshAirOn.svg";
import AiGateway from "assets/pages/Dashboard/DeviceIcon/AiGateway.svg";
import IoTGateway from "assets/pages/Dashboard/DeviceIcon/IoTGateway.svg";
import SmartPlug from "assets/pages/Dashboard/DeviceIcon/SmartPlug.svg";
import ModbusMeter from "assets/pages/Dashboard/DeviceIcon/ModbusMeter.svg";
import { Serie } from "@nivo/line";

export type DeviceProps = {
  device_name: string;
  device_id: string;
  agent_id: string;
  ui_location: number[];
  visible?: boolean;
  subdevices?: any;
  deviceStatusLoading?: boolean;
};

export interface DeviceIconProps {
  data: DeviceProps[];
}

export type DeviceListProps = {
  agentId: string;
  icon: any;
  isOpened: boolean;
  name: string;
}[];

export const DeviceListData: DeviceListProps = [
  {
    agentId: "bac0hvac",
    icon: AcIconOn,
    isOpened: true,
    name: "Air-condition",
  },
  {
    agentId: "itm_fcu",
    icon: AcIconOn,
    isOpened: true,
    name: "Air-condition",
  },
  {
    agentId: "ac_wifi_adapter",
    icon: AcIconOn,
    isOpened: true,
    name: "Air-condition",
  },
  {
    agentId: "ac_wifi_adaptor",
    icon: AcIconOn,
    isOpened: true,
    name: "Air-condition",
  },
  {
    agentId: "netatmo",
    icon: NetatmoOn,
    isOpened: false,
    name: "Environment Sensor",
  },
  {
    agentId: "son_env",
    icon: NetatmoOn,
    isOpened: false,
    name: "Environment Sensor (Sonoff)",
  },
  {
    agentId: "airveda",
    icon: NetatmoOn,
    isOpened: false,
    name: "Environment Sensor (Airveda)",
  },
  {
    agentId: "tuya_air_quality",
    icon: NetatmoOn,
    isOpened: false,
    name: "Environment Sensor (Tuya Air Quality)",
  },
  {
    agentId: "tuya_env_relay",
    icon: NetatmoOn,
    isOpened: false,
    name: "Environment Sensor (Tuya)",
  },
  {
    agentId: "tuya_temp_humid",
    icon: NetatmoOn,
    isOpened: false,
    name: "Environment Sensor (Tuya Temp Humid)",
  },
  {
    agentId: "tuyacloudcurtain",
    icon: BlindOn,
    isOpened: false,
    name: "Blind",
  },
  {
    agentId: "tuyablinds",
    icon: BlindOn,
    isOpened: false,
    name: "Blind (Tuya)",
  },
  {
    agentId: "cctv",
    icon: CCTV,
    isOpened: false,
    name: "CCTV",
  },
  {
    agentId: "camera",
    icon: CCTV,
    isOpened: false,
    name: "CCTV",
  },
  {
    agentId: "tasmota",
    icon: FreshAirOn,
    isOpened: false,
    name: "Fresh Air",
  },
  {
    agentId: "itm_oau",
    icon: FreshAirOn,
    isOpened: false,
    name: "Fresh Air (ITM OAU)",
  },
  {
    agentId: "aigateway",
    icon: AiGateway,
    isOpened: false,
    name: "Ai Gateway",
  },
  {
    agentId: "iotgateway",
    icon: IoTGateway,
    isOpened: false,
    name: "IoT Gateway",
  },
  {
    agentId: "tuya_plug",
    icon: SmartPlug,
    isOpened: false,
    name: "Smart Plug",
  },
  {
    agentId: "modbus",
    icon: ModbusMeter,
    isOpened: false,
    name: "Smart Meter",
  },
];

export const EmptyDashboardData = [
  {
    key: "floor_1",
    title: "Load Fl.1",
    left: 78,
    top: 68,
    items: [
      {
        title: "Light/Plug Meter",
        device: "9c:a5:25:bb:e2:00:1",
        subdevice: 3,
        currentPower: 0,
        unit: "kW",
      },
      {
        title: "AC Meter",
        device: "9c:a5:25:bb:e2:00:2",
        subdevice: 3,
        currentPower: 0,
        unit: "kW",
      },
    ],
  },
  {
    key: "floor_2",
    title: "Load Fl.2",
    left: 78,
    top: 55.5,
    items: [
      {
        title: "Light/Plug Meter",
        device: "9c:a5:25:bb:e2:00:3",
        subdevice: 3,
        currentPower: 0,
        unit: "kW",
      },
      {
        title: "AC Meter",
        device: "9c:a5:25:bb:e2:00:4",
        subdevice: 3,
        currentPower: 0,
        unit: "kW",
      },
    ],
  },
  {
    key: "grid",
    title: "Buy-in Power",
    left: 20,
    top: 77,
    items: [
      {
        title: "MEA Meter",
        currentPower: 0,
        unit: "kW",
      },
    ],
  },
  {
    key: "solar",
    title: "Output Power",
    left: 24,
    top: 24,
    items: [
      {
        title: "Inverter01",
        currentPower: 0,
        unit: "kW",
        timestamp: "",
      },
      {
        title: "Inverter02",
        currentPower: 0,
        unit: "kW",
        timestamp: "",
      },
      {
        title: "Inverter03",
        currentPower: 0,
        unit: "kW",
        timestamp: "",
      },
    ],
  },
  {
    key: "people",
    title: "People",
    left: 40,
    top: 50,
    unit: "unit",
    type: "camera",
    items: [
      {
        title: "Fl.1 Front Camera",
        currentPower: 0,
        unit: "people",
      },
      {
        title: "Fl.2 Left Camera",
        currentPower: 0,
        unit: "people",
      },
      {
        title: "Fl.2 Right Camera",
        currentPower: 0,
        unit: "people",
      },
    ],
  },
];

type SummaryDataProps = {
  realtimePowerConsumption?: Serie[] | [];
  dailyEnergyConsumption?: Serie[] | [];
  monthlyEnergyConsumption?: Serie[] | [];
  ranking?: Serie[] | [];
  co2Emission?: {
    today: number;
    thisMonth: number;
  };
};

export const MockRealtimePowerCon: SummaryDataProps = {
  realtimePowerConsumption: [
    {
      id: "realtimePowerConsumption",
      color: "#8BC6FF",
      data: [
        { x: "2021-11-16 00:00", y: 3.5 },
        { x: "2021-11-16 00:30", y: 6.5 },
        { x: "2021-11-16 01:00", y: 9.2 },
        { x: "2021-11-16 01:30", y: 5.3 },
        { x: "2021-11-16 02:00", y: 7.1 },
        { x: "2021-11-16 02:30", y: 8.2 },
        { x: "2021-11-16 03:00", y: 5.7 },
        { x: "2021-11-16 03:30", y: 7.3 },
        { x: "2021-11-16 04:00", y: 6.7 },
        { x: "2021-11-16 04:30", y: 7.1 },
        { x: "2021-11-16 05:00", y: 7.9 },
        { x: "2021-11-16 05:30", y: 8.2 },
        { x: "2021-11-16 06:00", y: 8.5 },
        { x: "2021-11-16 06:30", y: 9.2 },
        { x: "2021-11-16 07:00", y: 9.1 },
        { x: "2021-11-16 07:30", y: 12.4 },
        { x: "2021-11-16 08:00", y: 37.3 },
        { x: "2021-11-16 08:30", y: 49.7 },
        { x: "2021-11-16 09:00", y: 82.6 },
        { x: "2021-11-16 09:30", y: 103.3 },
        { x: "2021-11-16 10:00", y: 118.6 },
        { x: "2021-11-16 10:30", y: 127.3 },
        { x: "2021-11-16 11:00", y: 122.6 },
        { x: "2021-11-16 11:30", y: 118.1 },
        { x: "2021-11-16 12:00", y: 142.2 },
        { x: "2021-11-16 12:30", y: 153.1 },
        { x: "2021-11-16 13:00", y: 142.7 },
        { x: "2021-11-16 13:30", y: 158.5 },
        { x: "2021-11-16 14:00", y: 162.2 },
        { x: "2021-11-16 14:30", y: 147.6 },
        { x: "2021-11-16 15:00", y: 164.5 },
        { x: "2021-11-16 15:30", y: 152.6 },
        { x: "2021-11-16 16:00", y: 136.2 },
        { x: "2021-11-16 16:30", y: 112.1 },
        { x: "2021-11-16 17:00", y: 81.5 },
        { x: "2021-11-16 17:30", y: 72.5 },
        { x: "2021-11-16 18:00", y: 42.6 },
        { x: "2021-11-16 18:30", y: 41.9 },
        { x: "2021-11-16 19:00", y: 32.2 },
      ],
    },
  ],
  dailyEnergyConsumption: [
    {
      id: "dailyEnergyConsumption",
      color: "#8BC6FF",
      data: [
        { x: "2021-11-01 00:00", y: 421.5 },
        { x: "2021-11-02 00:00", y: 482.1 },
        { x: "2021-11-03 00:00", y: 491.4 },
        { x: "2021-11-04 00:00", y: 409.1 },
        { x: "2021-11-05 00:00", y: 443.0 },
        { x: "2021-11-06 00:00", y: 14.5 },
        { x: "2021-11-07 00:00", y: 11.2 },
        { x: "2021-11-08 00:00", y: 489.3 },
        { x: "2021-11-09 00:00", y: 427.2 },
        { x: "2021-11-10 00:00", y: 415.9 },
        { x: "2021-11-11 00:00", y: 385.7 },
      ],
    },
  ],
  ranking: [
    {
      id: "dailyEnergyConsumption",
      color: "#8BC6FF",
      data: [
        { x: "2021-11-01 00:00", y: 421.5 },
        { x: "2021-11-02 00:00", y: 482.1 },
        { x: "2021-11-03 00:00", y: 491.4 },
        { x: "2021-11-04 00:00", y: 409.1 },
        { x: "2021-11-05 00:00", y: 443.0 },
        { x: "2021-11-06 00:00", y: 314.5 },
        { x: "2021-11-07 00:00", y: 511.2 },
        { x: "2021-11-08 00:00", y: 489.3 },
        { x: "2021-11-09 00:00", y: 427.2 },
        { x: "2021-11-10 00:00", y: 415.9 },
        { x: "2021-11-11 00:00", y: 385.7 },
      ],
    },
  ],
  monthlyEnergyConsumption: [
    {
      id: "monthlyEnergyConsumption",
      color: "#8BC6FF",
      data: [
        { x: "2021-01-30 00:00", y: 12043 },
        { x: "2021-02-28 00:00", y: 13294 },
        { x: "2021-03-30 00:00", y: 12358 },
        { x: "2021-04-30 00:00", y: 11582 },
        { x: "2021-05-30 00:00", y: 12395 },
        { x: "2021-06-30 00:00", y: 12558 },
        { x: "2021-07-30 00:00", y: 11895 },
        { x: "2021-08-30 00:00", y: 13290 },
        { x: "2021-09-30 00:00", y: 12904 },
        { x: "2021-10-30 00:00", y: 12490 },
        { x: "2021-11-30 00:00", y: 18942 },
      ],
    },
  ],
  co2Emission: {
    today: 426,
    thisMonth: 2317,
  },
};
