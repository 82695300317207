import { DeviceInput, DeviceName, FilterContainer, FilterWrapper, Icon, Tab, Tablist } from "./Components";
import FilterIcon from "assets/pages/Dashboard/Filter.svg";
import { useMemo, useState } from "react";
import { DeviceListProps } from "MockData/DeviceData";

interface DeviceFilterProps {
  selectedDeviceList?: {
    [agent_id: string]: {
      agentId: string;
      icon: any;
      isOpened: boolean;
      name: string;
    }
  };
  handleSelectDevice?: (agentId: string) => void;
  deviceList: any;
}

export const DeviceFilter = ({ selectedDeviceList, handleSelectDevice, deviceList }: DeviceFilterProps) => {
  const [isOpened, setIsOpened] = useState(false);

  const agentsSet = useMemo(() => {
    const agentsSet = new Set();
    //Prep a set of agents
    if (!deviceList) return agentsSet;
    deviceList.forEach((item: any) => {
      const agentId = item.agent_id;
      if (agentId) {
        agentsSet.add(agentId);
      }
    });
    return agentsSet;
  }, [deviceList])

  return (
    <FilterContainer style={{ position: "relative", top: 0 }}>
      <FilterWrapper onClick={() => setIsOpened(!isOpened)}>
        <Icon width={18} height={18} src={FilterIcon} />
      </FilterWrapper>

      {isOpened && (
        <Tablist>
          {selectedDeviceList &&
            Object.keys(selectedDeviceList)
              .filter((item: string) => agentsSet.has(item))
              .map((item: string) => (
                <Tab key={item}>
                  <DeviceInput type="checkbox" checked={selectedDeviceList?.[item].isOpened} onChange={() => handleSelectDevice && handleSelectDevice(item)} />
                  <Icon width={22} height={22} src={selectedDeviceList?.[item].icon} />
                  <DeviceName>{selectedDeviceList?.[item]?.name}</DeviceName>
                </Tab>
              ))}
        </Tablist>
      )}
    </FilterContainer>
  );
};
