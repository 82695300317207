import { useEffect, useState, useMemo, useContext } from "react";
import styled from "styled-components";
import { Row, Col } from "antd";
import { Graph1, H3, Subtitle } from "components/Common/components";
import { BuildingInfo } from "./BuildingInfo";
import { BuildingEvaluation } from "./BuildingEvaluation";
import { AccumulateSaving } from "./AccumulateSaving";
import { Serie } from "@nivo/line";
import { DailySaving } from "./DailySaving";
import {
  AccumulateSavingMockData,
  DailySavingMockData,
  EmptySummaryData,
  MockDataBuildingEvaluation,
} from "MockData/EnergyInformation";
import BuildingEvaluationColumnsJson from "configs/building_evaluation_columns.json";
import { FirebaseContext } from "../../../../firebase";
import { energy_info } from "MockData/FirebasePath";
import { convertFbData } from "utils/convertFbData";
import { checkVisible } from "utils/checkVisible";

type SummaryBlockProp = {
  title: string;
  value: number | string;
  unit: string;
  targetValue: number | string;
  bgColor: string;
  iconUrl: string;
};

type SummaryBlockProps = {
  data: SummaryBlockProp;
};

const StyledCol = styled(Col) <any>`
  display: block;
  flex: ${(props) => `0 0 ${(props.editedXl / 24) * 100}%`};
  max-width: ${(props) => `${(props.editedXl / 24) * 100}%`};
  margin-bottom: 18px;
  @media (min-width: 576px) {
    flex: ${(props) => `0 0 ${(props.editedSm / 24) * 100}%`};
    max-width: ${(props) => `${(props.editedSm / 24) * 100}%`};
  }
`;

const SumamryContainer = styled.div<any>`
  position: relative;
  height: 180px;
  padding: 12px;
  display: flex;
  justify-content: flex-start;
  background-color: ${(props) => `${props.bgColor}32`};
  border-radius: 16px;
`;

const IconContainer = styled.div`
  width: 42px;
  margin-right: 12px;
`;

const Icon = styled.img`
  width: 100%;
`;

const Span = styled.span`
  font-size: 18px;
  margin-left: 3px;
`;

const BottomContainer = styled.div<any>`
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 56px;
  border-radius: 0px 0px 16px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.bgColor}20;
`;

const Target = styled.p`
  font-size: 16px;
  margin-bottom: 0px;
  font-weight: 500;
`;

const ProgressBarContainer = styled.div`
  position: absolute;
  top: -7px;
  width: 100%;
`;

const Progress = styled.div`
  width: 80%;
  height: 16px;
  border-radius: 8px;
  padding: 1px;
  margin-left: 10%;
  background-color: white;
  overflow: hidden;
`;

const ProgressBar = styled.div<any>`
  height: 100%;
  width: ${(props) => props.value}%;
  background-color: ${(props) => props.bgColor};
  display: flex;
  align-items: center;
  font-size: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
  border-radius: 18px;
`;

const SummaryBlock = ({ data }: SummaryBlockProps) => {
  let percent = ((Number(data.value) / Number(data.targetValue)) * 100).toFixed(1);
  return (
    <StyledCol editedSm={4.8} editedXl={4.8}>
      <SumamryContainer bgColor={data.bgColor}>
        <IconContainer>
          <Icon src={data.iconUrl} />
        </IconContainer>
        <Row>
          <Col sm={24} xl={24}>
            <Row>
              <Col sm={24} xl={24}>
                <Subtitle>{data.title}</Subtitle>
                <Graph1>Actual</Graph1>
              </Col>
            </Row>
            <Row>
              <Col sm={24} xl={24}>
                <H3 color={data.bgColor} fontSize={32} mt={16}>
                  {data.value}
                  <Span>{data.unit}</Span>
                </H3>
              </Col>
            </Row>
          </Col>
        </Row>
        <BottomContainer bgColor={data.bgColor}>
          {data.title !== "E-baseline" && (
            <>
              <ProgressBarContainer>
                <Progress>
                  <ProgressBar value={percent} bgColor={data.bgColor}>
                    <span style={{ margin: "0px 0px 2px 8px" }}>{percent} %</span>
                  </ProgressBar>
                </Progress>
              </ProgressBarContainer>
              <Target>
                Target {data.targetValue} {data.unit}
              </Target>
            </>
          )}
        </BottomContainer>
      </SumamryContainer>
    </StyledCol>
  );
};

const Information = ({ data }: any) => {
  const firebase = useContext<any>(FirebaseContext);
  const [summaryData, setSummaryData] = useState<SummaryBlockProp[]>(EmptySummaryData);
  const [infoData, setInfoData] = useState<any>();
  const [buildingNameData, setBuildingNameData] = useState<any>();
  const [buildingData, setBuildingData] = useState<any>();
  const [accumulateSavingData, setAccumulateSavingData] = useState<Serie[]>();
  const [dailySavingData, setDailySavingData] = useState<any>();

  const BuildingEvaluationColumns: any = useMemo(() => BuildingEvaluationColumnsJson, []);


  function fetchData(didMount: boolean) {
    if (didMount) {
      firebase.db.ref(energy_info).off("value");
    } else {
      firebase.db.ref(energy_info).on("value", function (snap: { val: () => any }) {
        if (snap) {
          const value = snap.val();
          const summaryDataFb: any = convertFbData("energy", "summary_data", value);
          const buildingInfoFb: any = convertFbData("energy", "building_info", value);
          const buildingNameFb: any = convertFbData("energy", "building_name", value);
          if (summaryDataFb) {
            setSummaryData(summaryDataFb);
          }
          if (buildingInfoFb) {
            setInfoData(buildingInfoFb);
          }
          if (buildingNameFb) {
            setBuildingNameData(buildingNameFb);
          }
        }
      });
    }
  }

  useEffect(() => {
    fetchData(false);
    return () => {
      fetchData(true);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setBuildingData(MockDataBuildingEvaluation);
    setAccumulateSavingData(AccumulateSavingMockData);
    setDailySavingData(DailySavingMockData);
  }, []);

  return (
    <>
      <Row gutter={[18, 18]}>
        {checkVisible("energy", "information", "SummaryBlock") && summaryData.map((data: SummaryBlockProp) => <SummaryBlock data={data} />)}
      </Row>
      <Row gutter={[18, 18]}>
        <Col sm={8} xl={8}>
          {checkVisible("energy", "information", "BuildingInfo") && <BuildingInfo buildingNameData={buildingNameData} infoData={infoData} />}
        </Col>
        <Col sm={16} xl={16}>
          {checkVisible("energy", "information", "BuildingEvaluation") && (
            <BuildingEvaluation data={buildingData} columns={BuildingEvaluationColumns} />
          )}
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          {checkVisible("energy", "information", "DailySaving") && <DailySaving dailySavingData={dailySavingData} />}
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          {checkVisible("energy", "information", "AccumulateSaving") && <AccumulateSaving accumulateSavingData={accumulateSavingData} />}
        </Col>
        {/* <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          {checkVisible("energy", "information", "SummaryOfEnergySavingMeasures") && (
            <SummaryOfEnergySavingMeasures data={summaryOfEnergydata} columns={SummaryOfEnergyColumns} />
          )}
        </Col> */}
      </Row>
    </>
  );
};

export default Information;
