import { useContext, useMemo, useState } from "react";
import Indoor from "assets/pages/Dashboard/weather/Indoor.svg";
import GradientIndoorTop from "assets/pages/Dashboard/weather/GradientIndoorTop.svg";
import GradientIndoorBottom from "assets/pages/Dashboard/weather/GradientIndoorBottom.svg";
import CO2 from "assets/pages/Dashboard/weather/CO2.svg";
import AirQuality from "assets/pages/Dashboard/weather/Strom.svg";
import styled from "styled-components";
import { BlockContainer } from "components/Common/components";
import { useBuildingContext } from "layouts/BuildingProvider";
import tw from "twin.macro";
import { iot_devices_path } from "MockData/FirebasePath";
import { FirebaseContext } from "../../../firebase";

interface OutdoorType {
  data: any;
  siteName: string;
}

const Container = styled(BlockContainer)`
  background-color: tomato;
  border-radius: 16px;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 10px 10px 10px 10px;
  background: linear-gradient(1.82deg, rgba(226, 160, 49, 0.2) 47.97%, rgba(255, 255, 255, 0.1) 131.89%);
  box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.21), 0px 16px 32px -4px rgba(225, 230, 240, 0.24);
`;

const LabelCard = styled.div<any>`
  display: flex;
  height: 17px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 3px;
  padding-bottom: 2px;
  margin-top: 3px;
  font-weight: lighter;
  font-size: 10px;
  color: #ffffff;
  background: ${(props) => (props.value < 50 ? "#64BD4C" : props.value < 100 ? "#F5A623" : "#eb2300")};
  border-radius: 4px;
`;

const WeatherInfo = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  z-index: 10;
  padding: 0px 20px;
`;

const ImageDetailContainer = styled.div`
  position: relative;
  top: 5px;
  width: 26px;
  height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  border-radius: 13px;
`;

const Img = styled.img``;

const RadialImageTop = styled.img`
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 1;
  width: 70%;
`;

const RadialImageBottom = styled.img`
  position: absolute;
  bottom: 0px;
  left: 0px;
  z-index: 0;
  width: 40%;
`;

const WeatherDetail = styled.div`
  display: flex;
  justify-content: center;
  margin: 10px 30px 10px 30px;
  z-index: 9;
`;

const Detial = styled.div`
  margin-left: 10px;
  display: inline-grid;
`;

const WeatherHeaderContainer = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0px 0px 0px 0px;
`;

const ImageContainer = styled.div`
  position: relative;
  top: 0px;
  width: 46px;
  height: 46px;
  display: flex;
  margin-right: 10px;
  justify-content: center;
  align-items: center;
  background: #ffffff97;
  border-radius: 22.5px;
`;

const OutdoorImage = styled.img`
  width: 28px;
  height: 28px;
`;

const OutdoorText = styled.p`
  font-size: 10px;
  margin: 0px;
`;

const DotsContainer = styled.div(() => [tw`absolute bottom-2 left-[calc(50% - 8px)] flex gap-x-2 h-2`]);

const Dot = styled.div<any>`
  ${tw`w-2 h-2 rounded-full cursor-pointer`};
  ${({ active }) => (active ? `background: #0E7EE4` : `background: #C0E8FF`)}
`;

const FloorSelection = ({ floorList, selectedFloor, setSelectedFloor }: any) => {
  return (
    <>
      <DotsContainer>
        {floorList.map((list: string) => (
          <Dot
            key={list}
            active={selectedFloor.value === list}
            onClick={() => setSelectedFloor(
              { value: list, label: list.replace(/_/g, " ").replace(/\b\w/g, (l) => l.toUpperCase()) })}
          />
        ))}
      </DotsContainer>
    </>
  );
};

export const IndoorWeather = ({ data, siteName }: OutdoorType) => {
  const { buildingCode } = useBuildingContext();
  const [selectedFloor, setSelectedFloor] = useState({ label: "Floor1", value: "floor_1" });

  //---------------------------1) MAIN---------------------------//
  const firebase = useContext<any>(FirebaseContext);
  const [indoorData, setIndoorData] = useState<any>(null);
  const valueIndoorData = indoorData?.['environment']?.['subdev_0']
  function fetchFbData(didMount: boolean, deviceId: string) {
    if (didMount) {
      firebase.db.ref(iot_devices_path).off("value");
    } else {
      firebase.db.ref(iot_devices_path + '/' + deviceId).on("value", function (snap: { val: () => any }) {
        if (snap) {
          setIndoorData(snap.val());
        }
      });
    }
  }

  useMemo(() => {
    if (data?.[selectedFloor.value]) {
      fetchFbData(false, data?.[selectedFloor.value]);
    }
    return null
  }, [data?.[selectedFloor.value]])

  const floorList = useMemo(() => {
    if (!data) return []
    return Object.keys(data)
  }, [data])

  return (
    <>
      <Container>
        <RadialImageTop src={GradientIndoorTop} alt="gradient" width="100%" />
        <RadialImageBottom src={GradientIndoorBottom} alt="gradient" width="100%" />
        <WeatherInfo>
          <WeatherHeaderContainer style={{ width: "60%", paddingRight: 0 }}>
            <ImageContainer>
              <OutdoorImage src={Indoor} alt="indoor" />
            </ImageContainer>
            <OutdoorText>
              {siteName}
              <br />
              {selectedFloor.label}
            </OutdoorText>
          </WeatherHeaderContainer>
          <WeatherHeaderContainer style={{ justifyContent: "flex-end", width: "40%" }}>
            <h4 style={{ display: "flex", fontSize: "32px", padding: "0px", margin: "0px" }}>
              {valueIndoorData?.['temperature']}
              <span style={{ fontSize: "12px", paddingTop: "24px" }}>&#8451;</span>
            </h4>
          </WeatherHeaderContainer>
        </WeatherInfo>

        <WeatherDetail>
          <div style={{ width: "100%", display: "flex" }}>
            <ImageDetailContainer>
              <Img src={CO2} alt="co2" />
            </ImageDetailContainer>
            <Detial>
              <small style={{ fontSize: "10px", color: "#788796" }}>Carbon Dioxide</small>
              <strong style={{ fontSize: "14px" }}>
                {valueIndoorData?.['co2']}
                ppm
              </strong>
            </Detial>
          </div>

          <div style={{ width: "100%" }}>
            <div style={{ width: "100%", display: "flex" }}>
              <ImageDetailContainer>
                <Img src={AirQuality} alt="air" />
              </ImageDetailContainer>
              <Detial>
                <small style={{ fontSize: "10px", color: "#788796" }}>Air Quality Index</small>
                <strong style={{ fontSize: "14px", display: "flex" }}>
                  {calculateIndex((valueIndoorData?.['pm25']))}
                  &nbsp;{" "}
                  <LabelCard value={calculateIndex((valueIndoorData?.['pm25']))}>
                    {calculateIndex((valueIndoorData?.['pm25'])) < 50 ? "Good" : calculateIndex(valueIndoorData?.['pm25']) < 100 ? "Moderate" : "Unhealthy"}
                  </LabelCard>
                </strong>
              </Detial>
            </div>
          </div>
        </WeatherDetail>
        <WeatherDetail>
          <div style={{ width: "100%", display: "flex" }}>
            <ImageDetailContainer>
              <Img src={CO2} alt="humi" />
            </ImageDetailContainer>
            <Detial>
              <small style={{ fontSize: "10px", color: "#788796" }}>Humidity</small>
              <strong style={{ fontSize: "14px" }}>
                {valueIndoorData?.['humidity']}
                %
              </strong>
            </Detial>
          </div>
        </WeatherDetail>
        {(buildingCode === "DAIC" || buildingCode === "DAIR") &&
          <FloorSelection
            floorList={floorList}
            selectedFloor={selectedFloor}
            setSelectedFloor={setSelectedFloor}
          />
        }
      </Container>
    </>
  );
};

const calculateIndex = (value: number) => {
  if (value < 12) {
    return Math.floor(value / 12 * 50)
  } else if (value < 35.4) {
    return Math.floor(50 + ((value - 12.1) / 23.3 * 50))
  } else if (value < 55.4) {
    return Math.floor(100 + ((value - 35.5) / 20 * 50))
  } else if (value < 150.4) {
    return Math.floor(150 + ((value - 55.4) / 94.9 * 50))
  } else if (value < 200) {
    return Math.floor(201 + ((value - 150.5) / 12 * 50))
  } else {
    return 300
  }
}