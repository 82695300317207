import { Suspense, useCallback, useState } from "react";
import { AddUserModal } from "./AddUserModal";
import TableSection from "./TableSection";
import { ButtonSection } from "./ButtonSection";
import { LoadingPage } from "components/Common/LoadingPage/LoadingPage";
import { useDisclosure } from "utils/hooks";
import { deleteUser, postUsers } from "services/api.service";
import toast from "react-hot-toast";
import axios from "axios";
import { DeleteModal } from "components/Common/DeleteModal/DeleteModal";

export const UserManagementSection = ({ data, hotelCode, reload }: any) => {
  const addDialog = useDisclosure();
  const deleteDialog = useDisclosure();
  const [isView, setIsView] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disableDelete, setDisableDelete] = useState(false);
  const [selectedUser, setSelectedUser] = useState<any>();
  const [selectedUserId, setSelectedUserId] = useState<any>();

  const openViewUser = (userData: any) => {
    setIsView(true);
    setSelectedUser(userData);
    addDialog.onOpen();
  };

  const openDeleteUser = (userData: any) => {
    setSelectedUserId(userData.id);
    if (userData.role === "md") {
      setDisableDelete(true);
    } else {
      setDisableDelete(false);
    }
    deleteDialog.onOpen();
  };

  const onDeleteUser = async () => {
    setDisableDelete(true);
    try {
      await toast.promise(deleteUser(selectedUserId), {
        loading: "Sending...",
        success: (res: any) => {
          setDisableDelete(false);
          deleteDialog.onClose();
          reload();
          return <b>Successful</b>;
        },
        error: (error: any) => {
          deleteDialog.onClose();
          setDisableDelete(false);
          if (axios.isAxiosError(error) && error.response?.status === 400) {
            return <b>Failed</b>;
          }
          return <b>Failed</b>;
        },
      });
    } catch (e: unknown) {
      deleteDialog.onClose();
      setDisableDelete(false);
      if (e instanceof Error) {
        toast.error(<b>Failed to add user: {e.message}</b>);
      }
      throw e;
    }
  };

  const onAddUser = useCallback(async (payload: any) => {
    setLoading(true);
    try {
      await toast.promise(postUsers({ payload }), {
        loading: "Sending...",
        success: (res: any) => {
          setLoading(false);
          addDialog.onClose();
          reload();
          return <b>Successful</b>;
        },
        error: (error: any) => {
          setLoading(false);
          if (axios.isAxiosError(error) && error.response?.status === 400) {
            return <b>Failed</b>;
          }
          return <b>Failed</b>;
        },
      });
    } catch (e: unknown) {
      setLoading(false);
      if (e instanceof Error) {
        toast.error(<b>Failed to add user: {e.message}</b>);
      }
      throw e;
    }
  }, []);

  return (
    <>
      <ButtonSection onOpen={addDialog.onOpen} />
      {data && <TableSection data={data} openAddDevice={addDialog.onOpen} openViewUser={openViewUser} openDeleteUser={openDeleteUser} />}
      {data === null && <p>No matching data found</p>}
      {data === undefined && <LoadingPage />}
      <Suspense fallback={null}>
        {addDialog.isOpen && (
          <AddUserModal
            isOpen={addDialog.isOpen}
            onClose={addDialog.onClose}
            onAddUser={onAddUser}
            hotelCode={hotelCode}
            loading={loading}
            setLoading={setLoading}
            isView={isView}
            selectedUser={selectedUser}
          />
        )}
        <DeleteModal
          isOpen={deleteDialog.isOpen}
          onClose={deleteDialog.onClose}
          topic={"User id"}
          value={selectedUser && selectedUser["username"]}
          onDelete={onDeleteUser}
          selectedDeviceData={selectedUserId}
          disabled={disableDelete}
        />
      </Suspense>
    </>
  );
};
