import { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import Notification from "components/Notification";
import Profile from "components/Profile";
import { BiTimeFive } from "react-icons/bi";
import tw from "twin.macro";
import dayjs from "dayjs";
import { IoClose } from "react-icons/io5";
import axios from "axios";
import { useBuildingContext } from "layouts/BuildingProvider";

interface AlertProps {
  message: string;
  setClose: (close: boolean) => void;
  expireDate: number;
}

const Alert: React.FC<AlertProps> = ({ expireDate, message, setClose }) => {
  // now to timestamp
  const now = dayjs().unix();
  const handleOnClick = (setClose: any) => {
    const today = dayjs().startOf("day");
    const fiveDayLater = today.add(5, "day").unix();

    let targetDate;
    if (fiveDayLater > expireDate) {
      targetDate = today.add(1, "day").unix();
    } else {
      targetDate = Math.min(
        fiveDayLater,
        dayjs.unix(expireDate).subtract(5, "day").unix()
      );
    }

    if (typeof window !== "undefined") {
      localStorage.setItem("targetDate", targetDate.toString());
    }
    setClose(false);
  };

  return (
    <div
      className="bg-[#0E7EE4] text-white p-4 flex items-center justify-between"
      style={{ background: "#0E7EE4" }}
    >
      <div className="flex items-center justify-between w-full">
        <span>{message}</span>
        <IoClose
          className="mr-2 cursor-pointer"
          size={20}
          onClick={() => {
            handleOnClick(setClose);
          }}
        />
      </div>
    </div>
  );
};

const Container = styled.div`
  /* position: sticky; */
  top: 0px;
  width: 100%;
  height: 67px;
  display: flex;
  justify-content: space-between;
  padding: 8px 16px 8px 26px;
  align-items: center;
  color: #397cdd;
  font-weight: 600;
  z-index: 9999;
`;

const Block = styled.div`
  height: 100%;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PageName = tw.h1`inline text-2xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-[#0E7EE4] to-[#14B8B4]`;

type NavbarProps = {
  pageName: string;
};

const Clock = () => {
  let time = new Date().toLocaleTimeString();
  let [ctime, setCTime] = useState<any>();
  const updateTime = () => {
    time = new Date().toLocaleTimeString();
    setCTime(time);
  };
  setInterval(updateTime, 1000);

  return (
    <>
      <p style={{ margin: "0px 30px 2px 5px" }}> {ctime}</p>
    </>
  );
};

const Navbar = ({ pageName }: NavbarProps) => {
  const { buildingCode, buildingName } = useBuildingContext();
  const [close, setClose] = useState(true);
  const [expireDate, setExpireDate] = useState<number | null>(null);
  const [isShowAlertBanner, setIsShowAlertBanner] = useState(false);

  const targetDate = useMemo(() => {
    if (typeof window !== "undefined") {
      return parseInt(localStorage.getItem("targetDate") ?? "0");
    }
    return 0;
  }, []);

  const showAlertBanner = (
    todayDate: number,
    targetDate: number,
    expireDate: number | null
  ) => {
    if (expireDate !== null) {
      const differenceInDays = dayjs(expireDate * 1000).diff(
        dayjs(todayDate * 1000),
        "day"
      );
      const daysInTwoMonths = 2 * 30; // Approximate 2 months as 60 days
      // Check if the difference is greater than 2 months
      if (differenceInDays > daysInTwoMonths) {
        return false;
      }
      if (!targetDate) {
        return true;
      }
      if (targetDate <= todayDate && todayDate <= expireDate) {
        return true;
      }
    }
    return false;
  };

  const fetchExpireDate = async () => {
    if (!buildingCode) return;

    let modifiedBuildingCode = "";

    if (buildingCode === "DAIC") {
      modifiedBuildingCode = "daikin-ccc";
    } else if (buildingCode === "DAIR") {
      modifiedBuildingCode = "daikin-rd";
    }

    try {
      const { data } = await axios.post(
        `https://oyp0qhsie8.execute-api.us-east-1.amazonaws.com/dev/alto_noti_expiration_date/expiration_data/`,
        {
          sites_code: [modifiedBuildingCode],
        }
      );
      const expireDate = data?.[modifiedBuildingCode]?.["exp_date"];
      setExpireDate(expireDate);

      if (expireDate) {
        const expireDateParsed = dayjs(expireDate * 1000);
        const now = dayjs();
        const monthsDifference = expireDateParsed.diff(now, "month");
        // setIsShowAlertBanner(monthsDifference >= 2)
      }
    } catch (err) {
      console.log(err);
    }
  };
  const todayDate = useMemo(() => dayjs().startOf("day").unix(), []);

  useEffect(() => {
    fetchExpireDate();
    if (expireDate !== null) {
      setIsShowAlertBanner(showAlertBanner(todayDate, targetDate, expireDate));
    }
  }, [expireDate, todayDate, targetDate]);

  return (
    <>
      {expireDate != null && isShowAlertBanner && close && (
        <Alert
          message={`Your subscription is set to expire on ${dayjs(
            expireDate * 1000
          ).format(
            "MMMM D, YYYY"
          )} Please renew your subscription to continue enjoying our services. For renewal, contact us at sales@altotech.net`}
          setClose={setClose}
          expireDate={expireDate}
        />
      )}
      <Container>
        <Block>
          <PageName>
            {pageName.charAt(0).toUpperCase() + pageName.slice(1)}
          </PageName>
        </Block>
        <Block>
          <BiTimeFive />
          <Clock />
          <Notification />
          <Profile />
        </Block>
      </Container>
    </>
  );
};

export default Navbar;
