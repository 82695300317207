import React from 'react'
import styled from 'styled-components';
import AltoLogo from "assets/common/logo/AltoLogo_128x128.jpg";

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  padding: 12px 12px;
`

const Logo = styled.img`
  width: 30px;
  height: 28px;
`

const VersionContainer = styled.div`
  display: flex;
  column-gap: 24px;
`

const Footer = () => {
  return (
    <Container>
      <Logo src={AltoLogo} />
      <VersionContainer>
        <a
          target="_blank"
          href={'https://sites.google.com/altotech.net/altocero-releasenotes/'}>
          Version 2.1
        </a>
        <p>
          © 2023 AltoTech Global. All rights reserved.
        </p>
      </VersionContainer >
    </Container>
  )
}

export default Footer;
