import { useContext, useEffect, useRef, useState } from "react";
import { Row, Col } from "antd";
import { Building } from "./Building";
import { toast } from "react-hot-toast";
import { getDeviceByFloor, postDeviceControl, postDeviceControlAc } from "services/api.service";
import { DeviceListData, DeviceListProps } from "MockData/DeviceData";
import { getDatetime } from "utils/getDatetime";
import { responseErrorHandler } from "services/prepareAxios";
import { FirebaseContext } from "../../../firebase";
import { iot_devices_path } from "MockData/FirebasePath";

const tabNameList = [
  { title: "Control", value: "control" },
  { title: "Information", value: "information" },
  { title: "Automation", value: "automation" },
  { title: "History", value: "history" },
];

const FloorSection = ({
  selectedTab,
  setSelectedTab,
  dashboardData2,
  totalEnergyConsumption,
  handleViewGraph,
  siteName,
  buildingCode,
  floorMap,
}: any) => {
  const firebase = useContext<any>(FirebaseContext);
  const [buildingTitle] = useState("Building Energy Performance");
  const [buildingDesc, setBuildingDesc] = useState(getDatetime(new Date()));
  const [selectedDeviceList, setSelectedDeviceList] = useState<DeviceListProps>(DeviceListData);
  const [selectedDevice, setSelectedDevice] = useState<any>();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedModalTab, setSelectedModalTabName] = useState("control");
  const [command, setCommand] = useState();
  const [deviceList, setDeviceList] = useState<any>([]);
  const [visibleDeviceObject, setVisibleDeviceObject] = useState<any>();
  const [allIotStatusFb, setAllIotStatusFb] = useState<any>();

  const Ref: any = useRef(null);
  const [timer, setTimer] = useState(2);
  const [commandAc, setCommandAc] = useState<any>([]);

  const startTimer = (e: any) => {
    setTimer((prev: number) => {
      if (prev > 1) {
        return prev - 1;
      } else {
        setCommandAc((prev: any) => {
          sendDeviceControlCommandAc(
            selectedDevice.device_id,
            selectedDevice["subdevices"]["0"]["nickname"],
            { set_temperature: prev[prev.length - 1] },
            selectedDevice
          );
          return [];
        });
        clearInterval(Ref.current);
        return 0;
      }
    });
  };

  const clearTimer = (e: any) => {
    setTimer(2);
    if (Ref.current) {
      clearInterval(Ref.current);
    }
    const id = setInterval(() => {
      startTimer(e);
    }, 1000);
    Ref.current = id;
  };

  const onClickReset = (data: any) => {
    clearTimer(2);
    setCommandAc((prev: any) => {
      return [...prev, data];
    });
  };

  const checkLoading = (prev: any, data: any, agentId?: string) => {
    if (data?.event_status && data?.total_waiting_command) {
      if (data?.event_status === "success" && data?.total_waiting_command === 0) {
        return { ...data, ["loading"]: false };
      } else {
        return prev;
      }
    } else {
      return data;
    }
  };

  const fetchDeviceStatus = (didMount: boolean, deviceId: string, agentId?: string) => {
    if (didMount) {
      firebase.db.ref(iot_devices_path + `/${deviceId}`).off("value");
    } else {
      let tempDeviceId = agentId !== "camera" ? deviceId : deviceId.toLowerCase();
      firebase.db.ref(iot_devices_path + `/${tempDeviceId}`).on("value", function (snap: { val: () => any }) {
        if (snap) {
          let data = snap.val();
          if (data) {
            // AC or OAU
            if (agentId === "bac0hvac" || agentId === "itm_fcu" || agentId === "itm_oau") {
              setCommand((prev) => checkLoading(prev, data?.ac?.subdev_0));
            }
            // Environment Sensor
            else if (agentId === "son_env" || agentId === "tuya_air_quality" || agentId === "tuya_temp_humid" || agentId === "tuya_env_relay") {
              setCommand(data?.environment?.subdev_0);
            } else if (agentId === "netatmo") {
              setCommand(data?.environment?.subdev_1);
            } else if (agentId === "airveda") {
              setCommand(data?.environment?.subdev_0);
            }
            // Curtain
            else if (agentId === "tuyacloudcurtain" || agentId === "tuyablinds") {
              setCommand(data.device);
            }
            // Others
            else if (agentId === "cctv" || agentId === "camera") {
              setCommand(data?.camera_inout?.subdev_0);
            }
            else if (agentId === "tasmota") {
              setCommand(data.relay);
            }
          }
        }
      });
    }
  };

  const handleSelectFloor = (floorName: string) => {
    setSelectedTab(floorName);
    if (floorName !== "B") {
      fetchAllDevices(floorName);
    }
  };

  const handleOpenModal = (e: any) => {
    fetchDeviceStatus(false, e.device_id, e.agent_id);
    setSelectedDevice(e);
    setModalIsOpen(true);
  };

  const handleCloseModal = () => {
    fetchDeviceStatus(true, selectedDevice.device_id);
    setModalIsOpen(false);
    setCommand(undefined);
    setSelectedModalTabName("control");
  };

  const handleSelectDevice = (agentId: string) => {
    setSelectedDeviceList((prev: any) => {
      let tempPrev = [...prev];
      for (let i = 0; i < tempPrev.length; i++) {
        if (tempPrev[i].agentId === agentId) {
          tempPrev[i]["isOpened"] = !tempPrev[i]["isOpened"];
        }
      }
      return tempPrev;
    });
    setVisibleDeviceObject((prev: any) => {
      let tempPrev = { ...prev };
      tempPrev[agentId] = !tempPrev[agentId];
      return tempPrev;
    });
  };

  const handleSelectTabName = (e: string) => {
    setSelectedModalTabName(e);
  };

  const sendDeviceControlCommand = async (device_id: string, subdevice_name: string, command: any, deviceData?: any) => {
    try {
      await postDeviceControl(device_id, subdevice_name, command, deviceData);
      toast.success("Successfully");
    } catch (error) {
      responseErrorHandler(error);
    }
  };

  const sendDeviceControlCommandAc = async (device_id: string, subdevice_name: string, command: any, deviceData?: any) => {
    try {
      await postDeviceControlAc(device_id, subdevice_name, command, deviceData);
      toast.success("Successfully");
    } catch (error) {
      responseErrorHandler(error);
    }
  };

  const changeFbData = (device_id: string, subdevice: string, key: string, value: any) => {
    if (allIotStatusFb) {
      setAllIotStatusFb((prev: any) => {
        const tempPrev = { ...prev };
        tempPrev[device_id]["loading"] = true;
        //TODO Change to use agent when ready
        if (tempPrev[device_id]["ac"]) {
          tempPrev[device_id]["ac"]["subdev_0"][key] = value;
          tempPrev[device_id]["ac"]["subdev_0"]["new_set_temperature"] = value;
        }
        if (tempPrev[device_id]["device"]) {
          tempPrev[device_id]["device"]["subdev_0"][key] = value;
          tempPrev[device_id]["device"]["subdev_0"]["new_percent_position"] = value;
        }
        return tempPrev;
      });
    }
  };

  const handleChangeCommand = (key: string, value: any, agent_id: any, subdevice: any) => {
    if (agent_id === "bac0hvac" || agent_id === "itm_fcu") {
      if (key === "set_temperature") {
        setCommand((prev: any) => {
          let tempPrev: any = { ...prev };
          if (value === "increase" && tempPrev[key] <= 30) {
            tempPrev[key] += 1;
          } else if (value === "decrease" && tempPrev[key] >= 19) {
            tempPrev[key] -= 1;
          }
          tempPrev["loading"] = true;
          changeFbData(selectedDevice.device_id, subdevice, key, tempPrev[key]);
          if (buildingCode === "BGM") {
            sendDeviceControlCommand(selectedDevice.device_id, subdevice, tempPrev, selectedDevice);
          } else {
            onClickReset(tempPrev[key]);
            // sendDeviceControlCommandAc(selectedDevice.device_id, subdevice, { [key]: tempPrev[key] }, selectedDevice);
          }
          return tempPrev;
        });
      } else if (key === "mode") {
        setCommand((prev: any) => {
          let tempPrev: any = { ...prev };
          tempPrev[key] = value;
          changeFbData(selectedDevice.device_id, subdevice, key, tempPrev[key]);
          if (buildingCode === "BGM") {
            sendDeviceControlCommand(selectedDevice.device_id, subdevice, tempPrev, selectedDevice);
          } else {
            sendDeviceControlCommandAc(selectedDevice.device_id, subdevice, { [key]: tempPrev[key] }, selectedDevice);
          }
          return tempPrev;
        });
      } else if (key === "fan") {
        setCommand((prev: any) => {
          let tempPrev: any = { ...prev };
          tempPrev[key] = value;
          changeFbData(selectedDevice.device_id, subdevice, key, tempPrev[key]);
          if (buildingCode === "BGM") {
            sendDeviceControlCommand(selectedDevice.device_id, subdevice, tempPrev, selectedDevice);
          } else {
            sendDeviceControlCommandAc(selectedDevice.device_id, subdevice, { [key]: tempPrev[key] }, selectedDevice);
          }
          return tempPrev;
        });
      } else if (key === "louver") {
        setCommand((prev: any) => {
          let tempPrev: any = { ...prev };
          tempPrev[key] = value;
          changeFbData(selectedDevice.device_id, subdevice, key, tempPrev[key]);
          if (buildingCode === "BGM") {
            sendDeviceControlCommand(selectedDevice.device_id, subdevice, tempPrev, selectedDevice);
          } else {
            sendDeviceControlCommandAc(selectedDevice.device_id, subdevice, { [key]: tempPrev[key] }, selectedDevice);
          }
          return tempPrev;
        });
      }
    } else if (agent_id === "tasmota" || agent_id === "itm_oau") {
      if (key === "state") {
        setCommand((prev: any) => {
          let tempPrev: any = { ...prev };
          const changedValue = value === "on" ? "off" : "on";
          tempPrev[subdevice][key] = changedValue;
          sendDeviceControlCommand(selectedDevice.device_id, subdevice, { [key]: changedValue }, selectedDevice);
          return tempPrev;
        });
      } else if (key === "mode") {
        setCommand((prev: any) => {
          let tempPrev: any = { ...prev };
          const changedValue = value === "on" ? "off" : "on";
          tempPrev[key] = changedValue;
          sendDeviceControlCommand(selectedDevice.device_id, subdevice, tempPrev, selectedDevice);
          return tempPrev;
        });
      }
    } else if (agent_id === "tuyacloudcurtain" || agent_id === "tuyablinds") {
      if (key === "control_state" || key === "percent_position") {
        sendDeviceControlCommand(selectedDevice.device_id, subdevice, { [key]: value }, selectedDevice);
        changeFbData(selectedDevice.device_id, "subdev_0", key, value);
      }
    }
  };

  const fetchAllDevices = async (floor: string) => {
    try {
      setDeviceList([]);
      let listOfDeviceRes: any[] = [];
      if (floor !== "B") {
        const results = await getDeviceByFloor(floorMap[floor]["floor_id"]);
        listOfDeviceRes = results.data["floor_info"][0]["devices"].map((item: any) => ({
          ...item,
          ui_location: item.ui_location ? item.ui_location.split(",") : [0, 0],
        }));
      }
      setDeviceList(listOfDeviceRes);
      let visibleDevice: any = {};
      listOfDeviceRes.forEach((deviceObject: any) => {
        visibleDevice[deviceObject.agent_id] = true;
      });
      setVisibleDeviceObject(visibleDevice);
    } catch (error) {
      responseErrorHandler(error);
    }
  };

  useEffect(() => {
    setInterval(() => setBuildingDesc(getDatetime(new Date())), 10000);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      firebase.db.ref(iot_devices_path).once("value", function (snap: { val: () => any }) {
        let res = snap?.val();
        setAllIotStatusFb((prev: any) => {
          let newData = { ...res };
          let oldData = { ...prev };
          const deviceList = Object.keys(oldData);
          if (deviceList.length !== 0) {
            deviceList.forEach((deviceId: string) => {
              if (oldData[deviceId]["loading"] && oldData[deviceId]["loading"] === true) {
                if (oldData[deviceId]["ac"]) {
                  // Check ac
                  if (
                    oldData[deviceId]["ac"]["subdev_0"]["set_temperature"] === newData[deviceId]["ac"]["subdev_0"]["set_temperature"] &&
                    oldData[deviceId]["ac"]["subdev_0"]["mode"] === newData[deviceId]["ac"]["subdev_0"]["mode"] &&
                    oldData[deviceId]["ac"]["subdev_0"]["fan"] === newData[deviceId]["ac"]["subdev_0"]["fan"] &&
                    oldData[deviceId]["ac"]["subdev_0"]["louver"] === newData[deviceId]["ac"]["subdev_0"]["louver"]
                  ) {
                    oldData[deviceId] = newData[deviceId];
                  }
                } else if (oldData[deviceId]["device"]) {
                  // Check blind
                  try {
                    const newPosition = Number(newData[deviceId]["device"]["subdev_0"]["percent_position"]);
                    const oldPosition = Number(oldData[deviceId]["device"]["subdev_0"]["percent_position"]);
                    if (newPosition > oldPosition - 5 && newPosition < oldPosition + 5) {
                      oldData[deviceId] = newData[deviceId];
                    }
                  } catch (err) {
                    console.log(err);
                    oldData[deviceId] = newData[deviceId];
                  }
                }
              } else {
                oldData[deviceId] = newData[deviceId];
              }
            });
          } else {
            return newData;
          }
          return oldData;
        });
      });
    }, 1500);

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <Building
        deviceList={deviceList}
        buildingTitle={buildingTitle}
        buildingDesc={buildingDesc}
        selectedTab={selectedTab}
        setSelectedTab={handleSelectFloor}
        selectedDeviceList={selectedDeviceList}
        selectedDevice={selectedDevice}
        handleSelectDevice={handleSelectDevice}
        dashboardData2={dashboardData2}
        totalEnergyConsumption={totalEnergyConsumption}
        handleOpenModal={handleOpenModal}
        handleCloseModal={handleCloseModal}
        modalIsOpen={modalIsOpen}
        selectedTabName={selectedModalTab}
        tabNameList={tabNameList}
        handleSelectTabName={handleSelectTabName}
        command={command}
        handleChangeCommand={handleChangeCommand}
        visibleDeviceObject={visibleDeviceObject}
        handleViewGraph={handleViewGraph}
        siteName={siteName}
        allIotStatusFb={allIotStatusFb}
        buildingCode={buildingCode}
      />
    </>
  );
};

export default FloorSection;
