import { Row, Col } from "antd";
import { RealtimePowerGeneration } from "pages/OverviewPage/SummarySection/RealtimePowerGeneration";
import { SummaryBox } from "./SummaryBox";
import { Co2EmissionMd } from "./Co2Emission";
import { AggregatedEnergyConsumption } from "./AggregatedEnergyConsumption";
import { CurrentPowerGeneration } from "./CurrentPowerGeneration";
import { SolarAttributes } from "./SolarAttributes";
import { useBuildingContext } from "layouts/BuildingProvider";
import FloorConfig from "configs/building_config.json";
import { EnergyBaseline } from "pages/OverviewPage/SummarySection/EnergyBaseline";
import { convertDatetimeToStrDate } from "utils/convertDatetime";
import { getEnergyConsumption } from "services/api.service";
import { useEffect, useState } from "react";
import { prepLineGraph, prepPieGraph } from "utils/prepGraphData";
import { EnergyConsumption } from "pages/OverviewPage/SummarySection/EnergyConsumption";
import { PieDataProps } from "components/Common/Graph/PieChart";
import axios from "axios";
import real_time_power_map_by_building from "configs/real_time_power_map_by_building.json";

const SummarySection = ({
  realtimePowerData,
  realtimePowerConsumption,
  selectedTab,
  energySaving,
  floorMap,
}: any) => {
  // put this
  const realtimePowerMapByBuilding: any = real_time_power_map_by_building;
  const { buildingCode, buildingName } = useBuildingContext();
  const FloorConfigData: any = FloorConfig;
  const [energyConsumptionByDeviceType, setEnergyConsumptionByDeviceType] =
    useState<any>();
  const [energyConsumptionVsBaselineData, setEnergyConsumptionVsBaselineData] =
    useState<any>();
  const [energyConsumptionData, setEnergyConsumptionData] = useState<
    PieDataProps[] | any
  >();
  const [realtimePowerConsumptionGraph, setRealtimePowerConsumptionGraph] =
    useState<any>();
  const mockEnergySavingVsPacback = [
    {
      energy: 18.4,
      type: "Self-driving",
      unit: "kWh",
    },
    {
      energy: 27.0,
      type: "Baseline",
      unit: "kWh",
    },
  ];

  const fetchEnergyConsumptionBuilding = async (buildingCode: string) => {
    let today = new Date();
    let tempStarttime = convertDatetimeToStrDate(today);
    let tempEndtime = convertDatetimeToStrDate(today, "tomorrow");
    try {
      let response;
      if (buildingCode === "DAIC") {
        // If buildingCode is "DAIC", use the specific API endpoint
        response = await axios.get(
          `https://altocerobackend.azurewebsites.net/api/v3.0/daikin_energy_consumption/get_energy_consumption/?type=dashboard&building_name=DaikinCCC&starttime=${tempStarttime}&endtime=${tempEndtime}`
        );
      } else {
        // Otherwise, use the default API call
        response = await getEnergyConsumption({
          type: "dashboard",
          building_name: buildingName,
          starttime: tempStarttime,
          endtime: tempEndtime,
        });
      }
      const baselineConfig =
        buildingCode === "DAIC"
          ? ["power_load_fl1", "baseline"]
          : ["power_load_est"];
      let result = response.data.results;

      setRealtimePowerConsumptionGraph(
        prepLineGraph(
          result.building_power_kW,
          realtimePowerMapByBuilding[buildingCode],
          "timestamp"
        )
      );

      setEnergyConsumptionVsBaselineData(
        prepLineGraph(result.building_power_kW, baselineConfig, "timestamp")
      );
      setEnergyConsumptionData(
        buildingCode === "DAIR"
          ? {
              keys: ["floor_1", "floor_2", "floor_3"],
              indexBy: ["timestamp"],
              data: result?.energy_hourly_by_floor.map((item: any) => ({
                ...item,
                timestamp: item.timestamp?.substring(11, 16),
              })),
              legend: "timestamp",
            }
          : buildingCode === "SHE"
          ? {
              data: mockEnergySavingVsPacback,
              keys: ["energy"],
              indexBy: ["type"],
            }
          : prepPieGraph(result, buildingCode)
      );
      setEnergyConsumptionByDeviceType(
        buildingCode === "DAIR"
          ? {
              keys: ["HVAC Load", "Plug Load", "CDU Load"],
              indexBy: ["timestamp"],
              data: result?.device_type_energy_kWh.map((item: any) => ({
                ...item,
                timestamp: item.timestamp?.substring(11, 16),
              })),
              legend: "timestamp",
            }
          : {
              keys: ["energy"],
              indexBy: ["type"],
              data: result?.device_type_energy_kWh?.map((item: any) => ({
                ...item,
                energy: Math.round(item.energy * 100) / 100,
              })),
              legend: "device_type",
            }
      );
    } catch (err) {
      setEnergyConsumptionVsBaselineData(null);
    }
  };

  useEffect(() => {
    fetchEnergyConsumptionBuilding(buildingCode);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {["M", "F1", "F2", "F3", "F4", "F5", "F6", "F7", "F8", "F9"].includes(
        selectedTab
      ) && (
        <Row gutter={[18, 18]}>
          <Col xs={24} sm={24} md={6} lg={6} xl={6} style={{ zIndex: 9999 }}>
            <RealtimePowerGeneration
              data={realtimePowerConsumptionGraph}
              title={
                FloorConfigData[buildingCode]["summary_box"]["first"]["title"]
              }
              graphType={
                FloorConfigData[buildingCode]["summary_box"]["first"][
                  "graphType"
                ]
              }
            />
            {/* <SummaryBox
              title={"Real-time Power Consumption"}
              subtitle={floorMap[selectedTab]["summary_box_label"]}
              graphType={"line"}
              data={
                realtimePowerConsumption &&
                realtimePowerConsumption[selectedTab] &&
                realtimePowerConsumption[selectedTab][
                  "realtimePowerConsumption"
                ]
              }
              unit={"kW"}
            /> */}
          </Col>
          <Col xs={24} sm={24} md={6} lg={6} xl={6}>
            <EnergyBaseline
              data={energyConsumptionVsBaselineData}
              barData={energyConsumptionByDeviceType}
              title={
                FloorConfigData[buildingCode]["summary_box"]["second"]["title"]
              }
              graphType={
                FloorConfigData[buildingCode]["summary_box"]["second"][
                  "graphType"
                ]
              }
            />
            {/* <SummaryBox
              title={"Daily Energy Consumption"}
              subtitle={floorMap[selectedTab]["summary_box_label"]}
              graphType={"bar"}
              keys={["y"]}
              data={
                realtimePowerConsumption &&
                realtimePowerConsumption[selectedTab] &&
                realtimePowerConsumption[selectedTab]["dailyEnergyConsumption"]
              }
              unit={"kWh"}
            /> */}
          </Col>
          <Col xs={24} sm={24} md={6} lg={6} xl={6}>
            <EnergyConsumption
              data={energyConsumptionData}
              title={
                FloorConfigData[buildingCode]["summary_box"]["third"]["title"]
              }
              graphType={
                FloorConfigData[buildingCode]["summary_box"]["third"][
                  "graphType"
                ]
              }
            />
            {/* <SummaryBox
              title={"Monthly Energy Consumption"}
              subtitle={floorMap[selectedTab]["summary_box_label"]}
              graphType={"bar"}
              keys={["y"]}
              data={
                realtimePowerConsumption &&
                realtimePowerConsumption[selectedTab] &&
                realtimePowerConsumption[selectedTab][
                  "monthlyEnergyConsumption"
                ]
              }
              unit={"kWh"}
            /> */}
          </Col>
        </Row>
      )}
      {["S"].includes(selectedTab) && (
        <Row gutter={[18, 18]}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <RealtimePowerGeneration data={realtimePowerData} />
          </Col>
          <Col xs={24} sm={24} md={4} lg={4} xl={4}>
            <AggregatedEnergyConsumption
              energySavingThisMonth={energySaving.this_month}
              energySavingAccumulate={energySaving.accumulated}
            />
          </Col>
          <Col xs={24} sm={24} md={4} lg={4} xl={4}>
            <CurrentPowerGeneration
              energySavingThisMonth={energySaving.this_month}
              energySavingAccumulate={energySaving.accumulated}
            />
          </Col>
          <Col xs={24} sm={24} md={4} lg={4} xl={4}>
            <SolarAttributes
              energySavingThisMonth={energySaving.this_month}
              energySavingAccumulate={energySaving.accumulated}
            />
          </Col>
        </Row>
      )}
    </>
  );
};

export default SummarySection;
