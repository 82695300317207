import { Row, Col } from "antd";
import { BuildingPerformance, CircleEnergyPerformance } from "./BuildingPerformance";
import { BlockBody, BlockDesc, BlockHeader, Container, SubBlockHeader } from "./Components";
import { DeviceListProps } from "MockData/DeviceData";
import { SelfDrivingTab } from "components/Common/SelfDrivingTab/SelfDrivingTab";
import { CameraDropdownDaikin } from "./CameraDropdownDaikin2";
import { FloorModel } from "./FloorModel/FloorModel";
import { CameraDropdownGardenWing } from "./CameraDropdownGardenWing";
import { CameraDropdownDaikinRD } from "./CameraDropdownDaikinRD";

interface BuildingProps {
  deviceList: any[];
  selectedTab: string;
  setSelectedTab: (tabName: string) => void;
  buildingTitle: string;
  buildingDesc: string;
  selectedDeviceList: DeviceListProps;
  handleSelectDevice: (agentId: string) => void;
  selectedDevice: any;
  dashboardData2?: any;
  totalEnergyConsumption: any;
  handleOpenModal: (e: any) => void;
  handleCloseModal: () => void;
  modalIsOpen: boolean;
  selectedTabName: string;
  tabNameList: { title: string; value: string }[];
  handleSelectTabName: (tab: string) => void;
  command: any;
  handleChangeCommand: any;
  visibleDeviceObject: { [agent_id: string]: boolean };
  handleViewGraph: any;
  siteName: string;
  allIotStatusFb: any;
  buildingCode: string;
}

export const Building = ({
  deviceList,
  buildingTitle,
  buildingDesc,
  buildingCode,
  selectedTab,
  selectedDevice,
  dashboardData2,
  totalEnergyConsumption,
  handleOpenModal,
  handleCloseModal,
  modalIsOpen,
  selectedTabName,
  tabNameList,
  handleSelectTabName,
  command,
  handleChangeCommand,
  visibleDeviceObject,
  handleViewGraph,
  siteName,
  allIotStatusFb,
}: BuildingProps) => {
  return (
    <>
      <Container>
        <Row gutter={[18, 18]}>
          <Col sm={24} md={12} xl={12}>
            <Row>
              <BlockHeader>{buildingTitle}</BlockHeader>
              {buildingCode === "SHE" && <SubBlockHeader>(Namrungreang 1992)</SubBlockHeader>}
            </Row>
            <Row>
              <BlockDesc>{buildingDesc}</BlockDesc>
            </Row>
          </Col>
          <Col sm={20} md={9} xl={9}>
            {buildingCode !== "SHE" && <BuildingPerformance totalEnergyConsumption={totalEnergyConsumption} />}
          </Col>
          <Col sm={4} md={3} xl={3} style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
            <CircleEnergyPerformance totalEnergyConsumption={totalEnergyConsumption} />
          </Col>
        </Row>
        <Row gutter={[18, 18]}>
          <Col sm={24} md={24} xl={24}>
            <BlockBody>
              <FloorModel
                deviceList={deviceList}
                selectedTab={selectedTab}
                dashboardData2={dashboardData2}
                handleOpenModal={handleOpenModal}
                handleCloseModal={handleCloseModal}
                modalIsOpen={modalIsOpen}
                selectedTabName={selectedTabName}
                tabNameList={tabNameList}
                handleSelectTabName={handleSelectTabName}
                selectedDevice={selectedDevice}
                command={command}
                handleChangeCommand={handleChangeCommand}
                visibleDeviceObject={visibleDeviceObject}
                handleViewGraph={handleViewGraph}
                hotel_code={buildingCode}
                allIotStatusFb={allIotStatusFb}
              />
              <SelfDrivingTab page={"overview"} />
              {siteName === "Garden Wing" && <CameraDropdownGardenWing />}
              {siteName === "Daikin CCC" && <CameraDropdownDaikin />}
              {siteName === "Daikin R&D" && <CameraDropdownDaikinRD />}
            </BlockBody>
          </Col>
        </Row>
      </Container>
    </>
  );
};
