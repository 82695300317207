import { useState, useEffect } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Sidebar from "components/Sidebar";
import styled from "styled-components";
import { routes } from " routes";
import Navbar from "components/Navbar";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import { BuildingProvider } from "./BuildingProvider";
import { DevicesProvider } from "./DevicesProvider";
import { UserProvider } from "./UserProvider";
import Footer from "components/Footer/Footer";

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  min-height: 100%;
  display: flex;
  background-color: #f9faff;
`;

const Main = styled.main`
  width: calc(100vw - 64px);
  min-height: calc(100vh - 67px);
  display: flex;
  flex-direction: column;
`;

const AdminLayout = (props: any) => {
  let history = useHistory();
  const url = window.location.pathname.split("/")[2];
  const [pageName, setPageName] = useState(url);
  const [siteName, setSiteName] = useState<string>("");
  useEffect(() => {
    const hotel_code = localStorage.getItem("hotel_code");
    const siteMap: any = {
      DAIC: "Daikin CCC",
      BGM: "Bgrimm Garden Wing",
    };
    if (hotel_code) {
      setSiteName(siteMap[hotel_code]);
    } else {
      setSiteName("AltoTech");
    }
  }, []);

  const handleSelectTab = (path: string) => {
    history.push(`/admin${path}`);
    setPageName(path.split("/")[1]);
  };

  const getRoutes = (routes: any[]): any => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  if (!localStorage.getItem("accessToken")) {
    return (
      <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
    );
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{siteName}</title>
        <meta name="description" content="AltoTech" />
        <link rel="icon" type="image/svg" href="favicon.ico" sizes="16x16" />
      </Helmet>
      <Container>
        <Sidebar
          routes={routes}
          selectedRoute={pageName}
          handleSelectTab={handleSelectTab}
        />
        <Main>
          <BuildingProvider>
            <DevicesProvider>
              <UserProvider>
                <Navbar pageName={pageName} />
                <Switch>{getRoutes(routes)}</Switch>
                <Footer />
              </UserProvider>
            </DevicesProvider>
          </BuildingProvider>
        </Main>
      </Container>
    </>
  );
};

export default AdminLayout;
