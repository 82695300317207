import { useEffect, useState } from "react";
import styled from "styled-components";
import { PageContainer } from "components/Common/components";
import Button from "components/Common/Button";
import { FiDownload, FiSearch } from "react-icons/fi";
import { Col, Row } from "antd";
import Select from "react-select";
import { convertDatetimeToStrDateNew } from "utils/convertDatetime";
import { getReportEnergy } from "services/api.service";
import { responseErrorHandler } from "services/prepareAxios";
import { useBuildingContext } from "layouts/BuildingProvider";
import TableSection from "./TableSection";
import { CSVLink } from "react-csv";
import { DateRangePicker } from "components/DateRangePicker/DateRangePicker";

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  padding: 0px 18px;
`;
const Iframe = styled.iframe`
  width: 100%;
  height: calc(100vh - 150px);
  border: 0px;
`;

const ReportPanel = styled.div`
  position: relative;
  background: linear-gradient(
    101.24deg,
    rgba(255, 255, 255, 0.8) 0%,
    rgba(255, 255, 255, 0.4) 100%
  );
  box-shadow: 1px 3px 15px rgba(196, 196, 196, 0.15);
  backdrop-filter: blur(20px);
  border-radius: 16px;
  padding: 18px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
  margin-bottom: 16px;
  z-index: 99999 !important;
`;

const ReportTypeBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const ReportInputBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
`;

const ReportInputTitle = styled.p`
  margin: 0px;
  margin-right: 12px;
  font-size: 16px;
  color: #212529;
`;

const selectCustomStyles = {
  control: (provided: any) => ({
    ...provided,
    borderRadius: "6px",
    width: "300px",
  }),
  menu: (provided: any) => ({
    ...provided,
    position: "absolute",
    overflow: "scroll",
  }),
};

const daikinoptions = [
  {
    value: "floor_1",
    label: "Energy consumption from 1st floor",
  },
  {
    value: "floor_2",
    label: "Energy consumption from 2nd floor",
  },
];

const shellOptions = [
  {
    value: "floor_1",
    label: "Energy consumption from entire store",
  },
];

export type TabNameProps = {
  value: string;
  title: string;
}[];

const ReportPage = () => {
  const { buildingCode } = useBuildingContext();
  const [summaryData, setSummaryData] = useState<any>(null);
  const [reportType, setReportType] = useState<any>();
  const [options, setOptions] = useState<any>([]);
  const [startdate, setStartdate] = useState(
    new Date(new Date().setDate(new Date().getDate() - 15))
  );
  const [enddate, setEnddate] = useState(new Date());
  const { buildingName } = useBuildingContext();

  const fetchSummaryData = async () => {
    setSummaryData(undefined);
    let tempStarttime = convertDatetimeToStrDateNew(startdate);
    let tempEndtime = convertDatetimeToStrDateNew(enddate);
    try {
      const response: any = await getReportEnergy({
        type: "floor_energy",
        building_name: buildingName,
        floor_name: reportType.value,
        starttime: tempStarttime,
        endtime: tempEndtime,
        sample: "daily",
      });
      let result = response.data;
      if (typeof result === "string") {
        // replace nan by 0 on result string before parse
        result = result.replace(/NaN/g, "0");
        result = JSON.parse(result);
      }
      setSummaryData(result.floor_data);
    } catch (err) {
      setSummaryData(null);
      responseErrorHandler(err);
    }
  };

  const handleSelectReportType = (floor: string) => {
    setReportType(floor);
  };

  useEffect(() => {
    if (buildingName === "Shell Select") {
      setOptions(shellOptions);
      setReportType(shellOptions[0]);
    } else {
      setOptions(daikinoptions);
      setReportType(options[0]);
    }
  }, []);

  return (
    <>
      <PageContainer>
        {buildingCode && buildingCode === "DAIR" ? (
          <Iframe
            src={
              "https://data-science-dashboard-daikin-rd.azurewebsites.net/apps/report?username=alto_dash_daikin_rd&password=Alto@daikinrd2023"
            }
          />
        ) : buildingCode && buildingCode === "DAIC" ? (
          <Iframe
            src={
              "https://data-science-dashboard-daikin-ccc.azurewebsites.net/apps/report?username=alto_dash_daikin_ccc&password=Alto@daikinccc2024"
            }
          />
        ) : (
          <div>
            <ReportPanel>
              <Row style={{ width: "100%" }}>
                <Col xs={20}>
                  <ReportTypeBox>
                    <ReportInputBox>
                      <ReportInputTitle>Report Type</ReportInputTitle>
                      <Select
                        value={reportType}
                        options={options}
                        styles={selectCustomStyles}
                        onChange={(val: any) => handleSelectReportType(val)}
                      />
                    </ReportInputBox>
                    <ReportInputBox>
                      <ReportInputTitle>Date</ReportInputTitle>
                      <DateRangePicker
                        startDate={startdate}
                        setStartDate={setStartdate}
                        endDate={enddate}
                        setEndDate={setEnddate}
                        marginBottom={"0"}
                      />
                    </ReportInputBox>
                  </ReportTypeBox>
                </Col>
                <Col
                  xs={4}
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <Button
                    active
                    title={""}
                    onClick={() => fetchSummaryData()}
                    size={"medium"}
                    bgColor={"#0E7EE4"}
                    width={132}
                    icon={<FiSearch size={15} color={"white"} />}
                  >
                    Search
                  </Button>
                </Col>
              </Row>
            </ReportPanel>
            {summaryData && summaryData.length !== 0 && (
              <Wrapper>
                <Button
                  active
                  title={""}
                  size={"medium"}
                  bgColor={"#0E7EE4"}
                  width={132}
                  icon={<FiDownload size={15} color={"white"} />}
                >
                  <CSVLink
                    data={summaryData ? summaryData : []}
                    style={{ color: "white" }}
                  >
                    Download
                  </CSVLink>
                </Button>
              </Wrapper>
            )}
            <br />
            <TableSection data={summaryData} />
          </div>
        )}
      </PageContainer>
    </>
  );
};

export default ReportPage;
