import { useState } from 'react';
import axios from 'axios';
import { API_CONSTANTS } from './api.constants';
import { responseErrorHandler } from './prepareAxios';

interface energyConsumptionProps {
  type: string
  building_name: string
  starttime: string
  endtime: string
  floor_name?: string
  sample?: string | number
}

const floorNameMap: { [floorName: string]: string } = {
  F1: 'floor_1',
  F2: 'floor_2',
}

export default function authHeader() {
  //@ts-ignore
  const accessToken = localStorage.getItem("accessToken")
  if (accessToken) {
    return {
      Authorization: "Token " + accessToken,
      "Content-Type": "application/json",
    };
  } else {
    return {};
  }
}

function authHeaderMultipart() {
  //@ts-ignore
  const accessToken = localStorage.getItem("accessToken")
  if (accessToken) {
    return {
      Authorization: "Token " + accessToken,
      'Content-Type': 'application/x-www-form-urlencoded'
    };
  } else {
    return {};
  }
}

function useToken() {
  const getToken = () => {
    const tokenString = sessionStorage.getItem('token');
    if (tokenString) {
      const userToken = JSON.parse(tokenString);
      return userToken?.token;
    }
    return null
  };

  const [token, setToken] = useState(getToken());

  const saveToken = (userToken: any) => {
    sessionStorage.setItem('token', JSON.stringify(userToken));
    setToken(userToken.token);
  };

  return {
    setToken: saveToken,
    token,
  };
}

async function loginUser(username: string, password: string) {
  const requestOptions: any = {
    url: API_CONSTANTS.LOGIN_URL,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: { username: username, password: password },
  };
  try {
    let responseJson = await axios.request(requestOptions);
    //@ts-ignore
    sessionStorage.setItem('login', true);
    return responseJson.data;
  } catch (error: any) {
    //@ts-ignore
    sessionStorage.setItem('login', false);
    var data = { requestError: error.message, response: error.response };
    return data;
  }
}

async function controlAPI(payload: any) {
  const token = sessionStorage.getItem('token');
  const requestOptions = {
    url: API_CONSTANTS.DEVICES_CONTROL_URL,
    method: 'POST',

    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `token ${token}`,
    },
    data: payload,
  };
  try {
    //@ts-ignore
    let responseJson = await axios.request(requestOptions);
    return responseJson.data;
  } catch (error: any) {
    responseErrorHandler(error);

    var data = { requestError: error.message, response: error.response };
    return data;
  }
}

const getDeviceByFloor = (floorId: number) => {
  return axios.get(API_CONSTANTS.FLOOR, {
    headers: authHeader(),
    params: { floor_id: floorId },
  })
}

const getDeviceAllFloors = () => {
  return axios.get(API_CONSTANTS.FLOOR, {
    headers: authHeader(),
  })
}

const getDeviceHistory = (
  starttime: any,
  endtime: any,
  device_id: any,
  subdevice_idx: any,
  device_activity: any,
  sample_min: any,
  type: string
) => {
  let url: string = `?RequestId=123&starttime=${starttime}&endtime=${endtime}&device_id=${device_id}&subdevice_idx=${subdevice_idx}&device_activity=${device_activity}&sample_min=${sample_min}&type=${type}`;
  return axios.get(API_CONSTANTS.HISTORY_DEVICE + url, {
    headers: authHeader(),
  });
}

const getEnergyConsumption = ({ type, building_name, starttime, endtime, floor_name, sample }: energyConsumptionProps) => {
  const apiEndpoint: any = {
    "default": API_CONSTANTS.ENERGY_CONSUMPTION,
    "Daikin CCC": API_CONSTANTS.DAIKIN_ENERGY_CONSUMPTION,
    "Garden Wing": API_CONSTANTS.ENERGY_CONSUMPTION,
    "Mint Tower": API_CONSTANTS.ENERGY_CONSUMPTION,
    "Shell Select": API_CONSTANTS.ENERGY_CONSUMPTION_V3,
    "Daikin R&D": API_CONSTANTS.ENERGY_CONSUMPTION_V3,
  }
  let endpoint = apiEndpoint[building_name] ? apiEndpoint[building_name] : apiEndpoint["default"];

  return axios.get(endpoint, {
    headers: authHeader(),
    params: { type, building_name, starttime, endtime, floor_name, sample },
  })
}

const getReportEnergy = ({ type, building_name, starttime, endtime, floor_name, sample }: energyConsumptionProps) => {
  return axios.get(API_CONSTANTS.REPORT, {
    headers: authHeader(),
    params: { type, building_name, starttime, endtime, floor_name, sample },
  })
}

const getEnergyConsumptionV3 = ({ type, building_name, starttime, endtime, floor_name, sample }: energyConsumptionProps) => {
  return axios.get(API_CONSTANTS.ENERGY_CONSUMPTION_V3, {
    headers: authHeader(),
    params: { type, building_name, starttime, endtime, floor_name, sample },
  })
}

const getEnergyConsumptionByFloor = ({ type, building_name, starttime, endtime, floor_name, sample }: energyConsumptionProps) => {
  return axios.get(API_CONSTANTS.ENERGY_CONSUMPTION, {
    headers: authHeader(),
    params: { type, building_name, starttime, endtime, floor_name, sample },
  })
}

const getEnergyConsumptionByFloorV3 = ({ type, building_name, starttime, endtime, floor_name, sample }: energyConsumptionProps) => {
  return axios.get(API_CONSTANTS.ENERGY_CONSUMPTION_V3, {
    headers: authHeader(),
    params: { type, building_name, starttime, endtime, floor_name, sample },
  })
}

const getSelfDrivingAction = (starttime: string, endtime: string, building: string) => {
  return axios.get(API_CONSTANTS.SELF_DRIVING, {
    headers: authHeader(),
    params: { starttime, endtime, building },
  })
}

const postC2D = ({ device_id, command, schema, buildingCode }: any) => {
  let sentCommand = { ...command }
  // TODO: Uncomment line below to enable source_username
  // if (getUsername()) {
  //   sentCommand = {
  //     ...command,
  //     source_username: getUsername()
  //   }
  // }
  const payload = {
    RequestId: 12345,
    topic: 'devicecontrol',
    action: [
      {
        device_id,
        agent_id: 'rl_action',
        subdevice_idx: 0,
        subdevice_name: 'subdev_0',
        room_name: buildingCode === "BGM" ? 'garden_wing' : 'room_daikinccc',
        command: sentCommand,
        schema: schema,
      }
    ],
  }
  return axios.post(API_CONSTANTS.C2D, payload, {
    headers: authHeader()
  })
}

const getEnergyByFloor = (starttime: string, endtime: string, floor_name: string) => {
  return axios.get(API_CONSTANTS.HISTORY_FLOOR, {
    headers: authHeader(),
    params: { starttime, endtime, floor_name: floorNameMap[floor_name] },
  })
}

const getAllDevices = () => {
  return axios.get(API_CONSTANTS.DEVICES, {
    headers: authHeader(),
  })
}

const postDevice = (payload: any) => {
  return axios.post(API_CONSTANTS.DEVICES, payload, { headers: authHeader() })
}

const deleteDevice = (device_id: string) => {
  return axios.delete(API_CONSTANTS.DEVICES, { data: { device_id }, headers: authHeader() })
}

const deleteAutomation = (automation_id: string) => {
  return axios.delete(API_CONSTANTS.DELETE_AUTOMATION, { params: { by: 'id', query: Number(automation_id) }, headers: authHeader() })
}

const deleteUser = (user_id: string) => {
  return axios.delete(API_CONSTANTS.USER, { params: { id: user_id }, headers: authHeader() })
}

const getAllBlobs = () => {
  return axios.get(API_CONSTANTS.BLOB, { headers: authHeader() })
}

const getBlobPathByName = (file: string) => {
  return axios.get(API_CONSTANTS.BLOB, {
    headers: authHeader(),
    params: { file },
  })
}

const deleteBlob = (blobName: string) => {
  return axios.delete(API_CONSTANTS.BLOB, { params: { file_name: blobName }, headers: authHeader() })
}

const getAllUsers = () => {
  return axios.get(API_CONSTANTS.USER, { headers: authHeader(), params: { type: 'md' } })
}

const postUsers = ({ payload }: any) => {
  return axios.post(API_CONSTANTS.SIGNUP, payload, {
    headers: authHeader(),
  })
}

const getAllAutomations = () => {
  return axios.get(API_CONSTANTS.AUTOMATION, {
    headers: authHeader(), params: { by: 'all' }
  })
}

const getAutomationsByType = (type: string) => {
  return axios.get(API_CONSTANTS.AUTOMATION, {
    headers: authHeader(), params: { by: 'trigger_type', query: type }
  })
}

const postAutomation = (payload: any): any => {
  return axios.post(API_CONSTANTS.AUTOMATION, payload, {
    headers: authHeader()
  })
}

const postBlob = (form: any) => {
  return axios.post(API_CONSTANTS.BLOB, form, { headers: authHeaderMultipart() });
}

const getNotification = ({ starttime, endtime }: any) => {
  return axios.get(API_CONSTANTS.NOTIFICATION, { params: { starttime, endtime }, headers: authHeader() })
}

const getRoomsByFloor = ({ floor_name }: any) => {
  return axios.get(API_CONSTANTS.ROOMS, { params: { floor_name }, headers: authHeader() })
}


const postDeviceControl = (
  deviceId: any,
  subdeviceName: string,
  command: any,
  deviceData?: any
) => {
  let subdeviceNickname
  if (subdeviceName) {
    subdeviceNickname = subdeviceName
  } else {
    const firstSubdeviceItem: any = Object.values(deviceData['subdevices'])[0]
    subdeviceNickname = firstSubdeviceItem['nickname']
  }
  let tempCommand: any = {}
  tempCommand['set_temperature'] = command['set_temperature']
  tempCommand['mode'] = command['mode']
  tempCommand['fan'] = command['fan']
  tempCommand['louver'] = command['louver']
  if (tempCommand['fan'] === 'medium') {
    tempCommand['fan'] = 'medium'
  }
  tempCommand['state'] = command['state']
  tempCommand['control_state'] = command['control_state']
  tempCommand['percent_position'] = command['percent_position']
  let data: any = {
    "topic": "devicecontrol",
    "action": {
      [deviceData['room_info']['room_name']]: {
        [subdeviceNickname]: {
          "command": tempCommand
        }
      }
    },
    "RequestId": "12345"
  }
  return axios({
    "method": "POST",
    "url": API_CONSTANTS.DEVICES_CONTROL_URL,
    "headers": authHeader(),
    "data": data,
  })
}

const postDeviceControlAc = (
  deviceId: any,
  subdeviceName: string,
  command: any,
  deviceData?: any
) => {
  let subdeviceNickname
  if (subdeviceName) {
    subdeviceNickname = subdeviceName
  } else {
    const firstSubdeviceItem: any = Object.values(deviceData['subdevices'])[0]
    subdeviceNickname = firstSubdeviceItem['nickname']
  }
  let data: any = {
    "topic": "devicecontrol",
    "action": {
      [deviceData['room_info']['room_name']]: {
        [subdeviceNickname]: {
          "command": command
        }
      }
    },
    "RequestId": "12345"
  }
  return axios({
    "method": "POST",
    "url": API_CONSTANTS.DEVICES_CONTROL_URL,
    "headers": authHeader(),
    "data": data,
  })
}


const postAutomations = (
  allowNotification: boolean,
  roomName: string,
  subDeviceName: string,
  parameter: string,
  comparator: string,
  value: string | number,
  active: boolean
) => {
  let payload: any = {
    automation_image: '',
    automation_name: 'automation_daikin',
    trigger: {
      trigger_device: {
        [roomName]: {
          [subDeviceName]: {
            [parameter]: {
              [comparator]: value,
            },
          },
        },
      },
    },
    condition: {
      condition_value: '',
      condition_event: '',
    },
    action: {},
    allow_notification: allowNotification ? active : false,
    notification_message: `${parameter} is higher than limit`,
  };

  return axios.post(
    API_CONSTANTS.AUTOMATION,
    {
      headers: authHeader(),
    },
    payload
  );
}

const getDevices = () => {
  return axios.get(API_CONSTANTS.DEVICES, {
    headers: authHeader(),
  });
}

const getAllMaintenanceTaks = (id?: number) => {
  return axios.get(API_CONSTANTS.MAINTENANCE, {
    params: {
      start_unix_timestamp: 1660948960, end_unix_timestamp: Math.round(new Date().getTime() / 1000), id: id ? id : 1
    }
  })
}

const getMaintenanceTaksByStatus = (status: string, id?: number) => {
  return axios.get(API_CONSTANTS.MAINTENANCE, {
    params: {
      start_unix_timestamp: 1660948960, end_unix_timestamp: Math.round(new Date().getTime() / 1000), id: id ? id : 1, status
    }
  })
}

const updateDevice = (payload: any) => {
  // return axios.patch(API_CONSTANTS.DEVICES, payload, {
  return axios.put('http://localhost:8002/api/v2.0/devices', payload, {
    headers: authHeader(),
  })
}

const updateMaintenanceTask = (payload: any) => {
  return axios.patch(API_CONSTANTS.MAINTENANCE, payload, {
    headers: authHeader(),
  })
}

export {
  useToken,
  loginUser,
  controlAPI,
  getDeviceByFloor,
  getDeviceAllFloors,
  getDeviceHistory,
  getEnergyConsumption,
  getEnergyConsumptionByFloor,
  getSelfDrivingAction,
  postC2D,
  getEnergyByFloor,
  getAllDevices,
  postDevice,
  getAllBlobs,
  getBlobPathByName,
  getAllUsers,
  postUsers,
  getAllAutomations,
  getAutomationsByType,
  postAutomation,
  postBlob,
  deleteDevice,
  getNotification,
  getEnergyConsumptionV3,
  getEnergyConsumptionByFloorV3,
  deleteAutomation,
  deleteUser,
  deleteBlob,
  getReportEnergy,
  getRoomsByFloor,
  postDeviceControl,
  postDeviceControlAc,
  postAutomations,
  getDevices,
  updateDevice,
  getAllMaintenanceTaks,
  getMaintenanceTaksByStatus,
  updateMaintenanceTask
};
