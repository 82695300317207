import OverviewPage from 'pages/OverviewPage'
import DashboardPage from 'pages/DashboardPage'
import FloorUsagePage from 'pages/FloorUsagePage'
import SettingPage from 'pages/SettingPage'
import EnergyPage from 'pages/EnergyPage'
import AnalyticPage from 'pages/AnalyticPage'
import ReportPage from 'pages/ReportPage'
import StorePage from 'pages/StorePage'
import AutomationPage from 'pages/AutomationPage'
import DocumentPage from 'pages/DocumentPage'
import MaintenancePage from 'pages/MaintenancePage'

import OverviewIcon from "assets/common/icon/overviewicon.svg"
import DashboardIcon from "assets/common/icon/dashboardicon.svg"
import FloorUsageIcon from "assets/common/icon/floorusageicon.svg"
import EnergyIcon from "assets/common/icon/energyicon.svg"
import AnalyticIcon from "assets/common/icon/analyticicon.svg"
import ReportIcon from "assets/common/icon/reporticon.svg"
import SettingIcon from "assets/common/icon/settingicon.svg"
import StoreIcon from "assets/common/icon/storeicon.svg"
import AutomationIcon from "assets/common/icon/automationicon.svg"
import DocumentIcon from "assets/common/icon/documenticon.svg"
import MaintenanceIcon from "assets/common/icon/maintenanceicon.svg"

export const routes = [
    {
        path: '/overview',
        name: 'Overview',
        icon: OverviewIcon,
        component: OverviewPage,
        layout: '/admin',
    },
    {
        path: '/floorusage',
        name: 'Floor Usage',
        icon: FloorUsageIcon,
        component: FloorUsagePage,
        layout: '/admin',
    },
    {
        path: '/dashboard',
        name: 'Dashboard',
        icon: DashboardIcon,
        component: DashboardPage,
        layout: '/admin',
    },
    {
        path: '/energy',
        name: 'Energy',
        icon: EnergyIcon,
        component: EnergyPage,
        layout: '/admin',
        disabled: true,
    },
    {
        path: '/analytic',
        name: 'Analytic',
        icon: AnalyticIcon,
        component: AnalyticPage,
        layout: '/admin',
    },
    {
        path: '/maintenance',
        name: 'Maintenance',
        icon: MaintenanceIcon,
        component: MaintenancePage,
        layout: '/admin',
    },
    {
        path: '/report',
        name: 'Report',
        icon: ReportIcon,
        component: ReportPage,
        layout: '/admin',
    },
    {
        path: '/automation',
        name: 'Automation',
        icon: AutomationIcon,
        component: AutomationPage,
        layout: '/admin',
    },
    {
        path: '/document',
        name: 'Document',
        icon: DocumentIcon,
        component: DocumentPage,
        layout: '/admin',
    },
    {
        path: '/store',
        name: 'Store',
        icon: StoreIcon,
        component: StorePage,
        layout: '/admin',
        disabled: true,
    },
    {
        path: '/setting',
        name: 'Setting',
        icon: SettingIcon,
        component: SettingPage,
        layout: '/admin',
    },
]