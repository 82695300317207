import styled from "styled-components";
import Tab from "components/Tab";
import { MainBlockContainer } from "components/Common/components";

type TabProps = "deviceManagement" | "userManagement";

interface settingTypeProps {
  selectedType: string;
  handleSelectType: (type: TabProps) => void;
  buildingCode?: string;
}

const Container = styled(MainBlockContainer)`
  background: transparent;
  margin-bottom: 0px;
  padding-top: 0px;
  padding-left: 0px;
  padding-bottom: 0px;
  margin-left: 0px;
  color: #586b7c;
`;

const SettingTypeList = [
  {
    value:
      "energy-consumption?username=alto_dash_daikin_ccc&password=Alto@daikinccc2024",
    title: "Energy Consumption",
  },
  {
    value: "iso50006?username=alto_dash_daikin_ccc&password=Alto@daikinccc2024",
    title: "Energy Saving",
  },
  {
    value:
      "energy-comparison?username=alto_dash_daikin_ccc&password=Alto@daikinccc2024",
    title: "HVAC Energy Comparison",
  },
  {
    value:
      "ac-env-zone?username=alto_dash_daikin_ccc&password=Alto@daikinccc2024",
    title: "AC Env Zone",
  },
];

const DaikinCCCSettingTypeSection = ({
  selectedType,
  handleSelectType,
}: settingTypeProps) => {
  console.log(selectedType);
  return (
    <>
      <Container>
        <Tab
          dataList={SettingTypeList}
          selectedValue={selectedType}
          handleSelect={handleSelectType}
        />
      </Container>
    </>
  );
};

export default DaikinCCCSettingTypeSection;
