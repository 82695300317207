import styled from "styled-components";
import { Row, Col, Slider, Spin } from "antd";
import ProgressBar from "react-customizable-progressbar";
import { HiPlus, HiMinus } from "react-icons/hi";
import AcModeCool from "assets/pages/Dashboard/DeviceControl/ac_mode_cool.svg";
import AcModeDry from "assets/pages/Dashboard/DeviceControl/ac_mode_dry.svg";
import AcModeFan from "assets/pages/Dashboard/DeviceControl/ac_mode_fan.svg";
import AcModeCoolActive from "assets/pages/Dashboard/DeviceControl/ac_mode_cool_active.svg";
import AcModeDryActive from "assets/pages/Dashboard/DeviceControl/ac_mode_dry_active.svg";
import AcModeFanActive from "assets/pages/Dashboard/DeviceControl/ac_mode_fan_active.svg";
import { SelfDrivingMode } from "../SelfDrivingMode";
import { FiPower } from "react-icons/fi";
import moment from "moment";
import { useEffect, useState } from "react";
import { useBuildingContext } from "layouts/BuildingProvider";
import LouverLevel1 from "assets/pages/Dashboard/DeviceControl/LouverLevel1.svg";
import LouverLevel2 from "assets/pages/Dashboard/DeviceControl/LouverLevel2.svg";
import LouverLevel3 from "assets/pages/Dashboard/DeviceControl/LouverLevel3.svg";
import LouverLevel4 from "assets/pages/Dashboard/DeviceControl/LouverLevel4.svg";
import LouverLevel5 from "assets/pages/Dashboard/DeviceControl/LouverLevel5.svg";
import LouverSwing from "assets/pages/Dashboard/DeviceControl/LouverSwing.svg";

type rlOutputStatus = {
  is_data_updated: boolean;
  output_enable: boolean;
  timestamp: string;
  unix_timestamp: number;
  output_temperature: number;
};

const Container = styled.div`
  width: 750px;
`;

const CenterDiv = styled.div`
  height: 280px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
`;

const Value = styled.div`
  width: 100%;
  position: absolute;
  margin: 0px;
  font-weight: bold;
  font-size: 48px;
  text-align: center;
  color: #065ba9;
  text-shadow: 0px 2px 5px rgba(161, 183, 205, 0.5);
  /* left: calc(50% - 72px); */
  top: calc(50% - 40px);
  user-select: none;
  display: flex;
  flex-direction: column;
  margin-bottom: 0px;
  align-items: center;
`;

const Header = styled.p`
  margin: 0px;
  text-align: left;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #212529;
  margin: 15px 0px 10px 0px;
`;

const ModeBoxContainer = styled.div`
  width: 100%;
  height: auto;
  padding: 15px 0px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

const ModeBox = styled.button<any>`
  width: 50px;
  height: 50px;
  background: ${(props) =>
    props.selected
      ? "linear-gradient(32.25deg, #0E7EE4 -0.24%, #14B8B4 99.76%);"
      : "linear-gradient(49.16deg, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0.4) 100%)"};
  box-shadow: 0px 3px 20px rgba(161, 183, 205, 0.2);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
`;

const ModeName = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 5px;
`;

const ModeIcon = styled.img`
  width: 20px;
`;

const FanModeIcon = styled.p<any>`
  margin: 0px;
  font-size: 24px;
  font-weight: 600;
  color: ${(props) => (props.selected ? "white" : "#7c7c7c")};
`;

const WrapSlider = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

const louverMarks = {
  0: {
    label: (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <strong>LV.1</strong>
        <img src={LouverLevel1} alt="louver" />
      </div>
    ),
  },
  20: {
    label: (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <strong>LV.2</strong>
        <img src={LouverLevel2} alt="louver" />
      </div>
    ),
  },
  40: {
    label: (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <strong>LV.3</strong>
        <img src={LouverLevel3} alt="louver" />
      </div>
    ),
  },
  60: {
    label: (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <strong>LV.4</strong>
        <img src={LouverLevel4} alt="louver" />
      </div>
    ),
  },
  80: {
    label: (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <strong>LV.5</strong>
        <img src={LouverLevel5} alt="louver" />
      </div>
    ),
  },
  100: {
    label: (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <strong>Swing</strong>
        <img src={LouverSwing} alt="louver" />
      </div>
    ),
  },
};

const getSetTemp = (command: any, agentId: string) => {
  if (!command) {
    return {
      temp: null,
      progress: 0,
    };
  }
  if (agentId) {
    return {
      temp:
        command.set_temperature > 0
          ? Number(command?.set_temperature)?.toFixed(0)
          : "-",
      progress:
        ((command.set_temperature - 18) / 13) * 100 > 0
          ? ((command.set_temperature - 18) / 13) * 100
          : 0,
    };
  } else {
    return {
      temp: null,
      progress: 0,
    };
  }
};

const mapLouver = (louverValue: string | number): any => {
  if (typeof louverValue === "string") {
    if (louverValue === "position_0") {
      return 0;
    } else if (louverValue === "position_1") {
      return 20;
    } else if (louverValue === "position_2") {
      return 40;
    } else if (louverValue === "position_3") {
      return 60;
    } else if (louverValue === "position_4") {
      return 80;
    } else if (louverValue === "swing") {
      return 100;
    } else {
      return 0;
    }
  } else {
    if (louverValue === 0) {
      return "position_0";
    } else if (louverValue === 20) {
      return "position_1";
    } else if (louverValue === 40) {
      return "position_2";
    } else if (louverValue === 60) {
      return "position_3";
    } else if (louverValue === 80) {
      return "position_4";
    } else if (louverValue === 100) {
      return "swing";
    } else {
      return "position_0";
    }
  }
};

const AcLouver = ({ command, handleChangeCommand, deviceData }: any) => {
  const [value, setValue] = useState<number>();

  const onChangeLouver = (percent: number) => {
    setValue(percent);
    handleChangeCommand("louver", mapLouver(percent), deviceData.agent_id, 0);
  };

  const onChange = (sliderPercent: number) => {
    setValue(sliderPercent);
  };

  useEffect(() => {
    setValue(command.louver);
  }, []);

  return (
    <Row>
      <Col xs={24}>
        <Header>Louver</Header>
      </Col>
      <Col xs={24} style={{ marginBottom: "72px", padding: "0px 24px" }}>
        <WrapSlider>
          <Slider
            marks={louverMarks}
            value={typeof value === "string" ? mapLouver(value) : value}
            tooltipVisible={true}
            onChange={(value: number) => onChange(value)}
            onAfterChange={(value: number) => onChangeLouver(value)}
            step={null}
          />
        </WrapSlider>
      </Col>
    </Row>
  );
};

export const AcControl = ({
  command,
  handleChangeCommand,
  deviceData,
  loading,
}: any) => {
  const { buildingCode } = useBuildingContext();
  const [selfDrivingStatus, setSelfDrivingStatus] = useState<
    rlOutputStatus | undefined
  >();

  if (!command) {
    return null;
  }

  // test

  return (
    <Container>
      <Row>
        <Col
          xs={12}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Row>
            <Col xs={6}>
              <CenterDiv>
                {command?.mode === "cool" && (
                  <HiMinus
                    size={30}
                    color={"#788796"}
                    style={{ cursor: "pointer", zIndex: 999 }}
                    onClick={() =>
                      handleChangeCommand(
                        "set_temperature",
                        "decrease",
                        deviceData.agent_id,
                        0
                      )
                    }
                  />
                )}
              </CenterDiv>
            </Col>
            <Col xs={12}>
              <CenterDiv style={{ paddingTop: "30px" }}>
                <ProgressBar
                  radius={100}
                  progress={getSetTemp(command, deviceData.agent_id).progress}
                  strokeWidth={12}
                  strokeColor="#0E7EE4"
                  strokeLinecap="butt"
                  trackStrokeWidth={14}
                  trackStrokeLinecap="butt"
                  cut={120}
                  rotate={-210}
                />
                <Value>
                  <div
                    style={{
                      height: "72px",
                      display: "flex",
                      alignItems: "flex-end",
                    }}
                  >
                    {command.mode === "cool" && (
                      <>
                        {getSetTemp(command, deviceData.agent_id).temp}{" "}
                        <small>°C</small>
                      </>
                    )}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                    <p
                      style={{
                        fontSize: "12px",
                        display: "flex",
                        alignItems: "flex-end",
                      }}
                    >
                      Room Temp.{" "}
                      {command?.room_temperature &&
                        Number(command?.room_temperature)?.toFixed(2)}{" "}
                      <small>°C</small>
                    </p>
                  </div>
                  {loading && (
                    <div style={{ marginTop: "-12px" }}>
                      <Spin />
                    </div>
                  )}
                </Value>
              </CenterDiv>
            </Col>
            <Col xs={6}>
              <CenterDiv>
                {command?.mode === "cool" && (
                  <HiPlus
                    size={30}
                    color={"#0E7EE4"}
                    style={{ cursor: "pointer", zIndex: 999 }}
                    onClick={() =>
                      handleChangeCommand(
                        "set_temperature",
                        "increase",
                        deviceData.agent_id,
                        0
                      )
                    }
                  />
                )}
              </CenterDiv>
            </Col>
          </Row>
          <Row>
            <Col xs={24}>
              <SelfDrivingMode
                command={command}
                deviceData={deviceData}
                selfDrivingStatus={selfDrivingStatus}
                setSelfDrivingStatus={setSelfDrivingStatus}
              />
            </Col>
          </Row>
        </Col>
        <Col xs={12}>
          <Row>
            <Col xs={24}>
              <Header>Mode</Header>
            </Col>
            <Col xs={6}>
              <ModeBoxContainer>
                <ModeBox
                  selected={command && command.mode === "off"}
                  onClick={() =>
                    handleChangeCommand("mode", "off", deviceData.agent_id, 0)
                  }
                >
                  <FiPower
                    stroke={
                      command && command.mode === "off" ? "white" : "#0E7EE4"
                    }
                    size={22}
                  />
                </ModeBox>
                <ModeName>Off</ModeName>
              </ModeBoxContainer>
            </Col>
            <Col xs={6}>
              <ModeBoxContainer>
                <ModeBox
                  selected={command && command.mode === "cool"}
                  onClick={() =>
                    handleChangeCommand("mode", "cool", deviceData.agent_id, 0)
                  }
                >
                  <ModeIcon
                    src={
                      command && command.mode === "cool"
                        ? AcModeCoolActive
                        : AcModeCool
                    }
                  />
                </ModeBox>
                <ModeName>Cool</ModeName>
              </ModeBoxContainer>
            </Col>
            <Col xs={6}>
              <ModeBoxContainer>
                <ModeBox
                  selected={command && command.mode === "fan"}
                  onClick={() =>
                    handleChangeCommand("mode", "fan", deviceData.agent_id, 0)
                  }
                >
                  <ModeIcon
                    src={
                      command && command.mode === "fan"
                        ? AcModeFanActive
                        : AcModeFan
                    }
                  />
                </ModeBox>
                <ModeName>Fan</ModeName>
              </ModeBoxContainer>
            </Col>
            <Col xs={6}>
              <ModeBoxContainer>
                <ModeBox
                  selected={command && command.mode === "dry"}
                  onClick={() =>
                    handleChangeCommand("mode", "dry", deviceData.agent_id, 0)
                  }
                >
                  <ModeIcon
                    src={
                      command && command.mode === "dry"
                        ? AcModeDryActive
                        : AcModeDry
                    }
                  />
                </ModeBox>
                <ModeName>Dry</ModeName>
              </ModeBoxContainer>
            </Col>
          </Row>
          <Row>
            <Col xs={24}>
              <Header>Fan Speed</Header>
            </Col>
            <Col xs={6}>
              <ModeBoxContainer>
                <ModeBox
                  disabled={command.mode === "off"}
                  selected={command && command.fan === "auto"}
                  onClick={() =>
                    handleChangeCommand("fan", "auto", deviceData.agent_id, 0)
                  }
                >
                  <FanModeIcon selected={command && command.fan === "auto"}>
                    A
                  </FanModeIcon>
                </ModeBox>
                <ModeName>Auto</ModeName>
              </ModeBoxContainer>
            </Col>
            <Col xs={6}>
              <ModeBoxContainer>
                <ModeBox
                  disabled={command.mode === "off"}
                  selected={command && command.fan === "low"}
                  onClick={() =>
                    handleChangeCommand("fan", "low", deviceData.agent_id, 0)
                  }
                >
                  <FanModeIcon selected={command && command.fan === "low"}>
                    L
                  </FanModeIcon>
                </ModeBox>
                <ModeName>Low</ModeName>
              </ModeBoxContainer>
            </Col>
            <Col xs={6}>
              <ModeBoxContainer>
                <ModeBox
                  disabled={command.mode === "off"}
                  selected={
                    command &&
                    (command.fan === "medium" || command.fan === "median")
                  }
                  onClick={() =>
                    handleChangeCommand("fan", "medium", deviceData.agent_id, 0)
                  }
                >
                  <FanModeIcon
                    selected={
                      command &&
                      (command.fan === "medium" || command.fan === "median")
                    }
                  >
                    M
                  </FanModeIcon>
                </ModeBox>
                <ModeName>Medium</ModeName>
              </ModeBoxContainer>
            </Col>
            <Col xs={6}>
              <ModeBoxContainer>
                <ModeBox
                  disabled={command.mode === "off"}
                  selected={command && command.fan === "high"}
                  onClick={() =>
                    handleChangeCommand("fan", "high", deviceData.agent_id, 0)
                  }
                >
                  <FanModeIcon selected={command && command.fan === "high"}>
                    H
                  </FanModeIcon>
                </ModeBox>
                <ModeName>High</ModeName>
              </ModeBoxContainer>
            </Col>
          </Row>
          {command && buildingCode === "DAIC" && (
            <AcLouver
              command={command}
              handleChangeCommand={handleChangeCommand}
              deviceData={deviceData}
            />
          )}
          <p
            style={{
              position: "absolute",
              bottom: "4px",
              right: "12px",
              marginBottom: "0px",
              fontSize: "12px",
              color: "#8c8c8c",
            }}
          >
            <span style={{ marginRight: "16px" }}>
              Source: {command?.source}{" "}
            </span>
            <span>
              Updated at:{" "}
              {command?.timestamp &&
                moment(command?.timestamp).format("YYYY-MM-DD HH:mm:ss")}
            </span>
          </p>
          {/* <p
            style={{
              position: "absolute",
              bottom: "-12px",
              right: "12px",
              marginBottom: "0px",
              fontSize: "12px",
              color: "#8c8c8c",
            }}
          >
            <span style={{ marginRight: "16px" }}>
              RL: {selfDrivingStatus?.output_temperature}{" "}
            </span>
            <span>
              Updated at:{" "}
              {command?.timestamp &&
                moment(
                  selfDrivingStatus?.unix_timestamp
                    ? selfDrivingStatus?.unix_timestamp * 1000
                    : 0
                ).format("YYYY-MM-DD HH:mm:ss")}
            </span>
          </p> */}
        </Col>
      </Row>
    </Container>
  );
};
