import { Col, Row } from "antd";
import styled from "styled-components";

import Netatmo from "assets/pages/Dashboard/DeviceControl/Netatmo.png";
import SonoffEnv from "assets/pages/Dashboard/DeviceControl/SonoffEnv.jpeg";
import TuyaTempHumid from "assets/pages/Dashboard/DeviceControl/TuyaTempHumid.png";
import TuyaAirQuality from "assets/pages/Dashboard/DeviceControl/TuyaAirQuality.png";
import FCU from "assets/pages/Dashboard/DeviceControl/FCU.png";
import TuyaWifiDualRelaySwitch from "assets/pages/Dashboard/DeviceControl/TuyaWifiDualRelaySwitch.png";
import DaikinFCU from "assets/pages/Dashboard/DeviceControl/DaikinFCU.png";
import CCTV from "assets/pages/Dashboard/DeviceControl/CCTV.png";
import TuyaBlinds from "assets/pages/Dashboard/DeviceControl/TuyaBlinds.png";
import { useBuildingContext } from "layouts/BuildingProvider";

const DeviceImage = styled.img`
  max-height: 190px;
  height: fit-content;
  margin-top: 48px;
`;

const TdLeft = styled.td`
  font-weight: 600;
  color: #0e7ee4;
  text-align: left;
  height: 40px;
`;

const Tr = styled.tr`
  border-bottom: 1px solid #dbe4ff;
`;

const ImageMap: any = {
  netatmo: Netatmo,
  son_env: SonoffEnv,
  tuya_temp_humid: TuyaTempHumid,
  tuya_air_quality: TuyaAirQuality,
  tuya_env_relay: TuyaWifiDualRelaySwitch,
  tuyablinds: TuyaBlinds,
  bac0hvac: FCU,
  itm_fcu: DaikinFCU,
  camera: CCTV,
};

export const DeviceInformation = ({ deviceInfo }: any) => {
  const { buildingCode } = useBuildingContext();
  console.log({ buildingCode });
  return (
    <>
      <Row>
        <Col xs={10} style={{ display: "flex", justifyContent: "center" }}>
          <DeviceImage src={ImageMap[deviceInfo.agent_id]} />
        </Col>
        <Col xs={14} style={{ padding: "10px" }}>
          <table style={{ width: "100%", height: "fit-content" }}>
            <tbody style={{ width: "100%", height: "fit-content" }}>
              {deviceInfo &&
                Object.keys(deviceInfo).map((key: string) => (
                  <Tr key={key}>
                    {buildingCode === "DAIR" ? (
                      <TdLeft>
                        {key === "device_name"
                          ? "model"
                          : key === "factory_id"
                          ? "S/N"
                          : key}
                      </TdLeft>
                    ) : (
                      <TdLeft>{key}</TdLeft>
                    )}
                    <td>{deviceInfo[key] && deviceInfo[key].toString()}</td>
                  </Tr>
                ))}
            </tbody>
          </table>
        </Col>
      </Row>
    </>
  );
};
