import { useContext, useEffect, useRef, useState } from "react";
import { Building } from "./Building";
import { FirebaseContext } from "../../../firebase";
import { iot_devices_path } from "MockData/FirebasePath";
import { toast } from "react-hot-toast";
import { responseErrorHandler } from "services/prepareAxios";
import { RoomsSection } from "./RoomsSection";
import { FloorEnergySummaryProps } from "./FloorEnergySummary";
import { DeviceModal } from "components/DeviceModal/DeviceModal";
import { RoomModal } from "components/Pages/FloorUsagePage/RoomModal/RoomModal";
import { useDisclosure } from "utils/hooks";
import { FloorGraphModal } from "components/Pages/FloorUsagePage/FloorGraphModal/FloorGraphModal";
import { postDeviceControl, postDeviceControlAc } from "services/api.service";

const RoomSelectionBody = ({
  deviceList,
  visibleDeviceObject,
  selectedTab,
  buildingName,
  buildingCode,
  selectedView,
  roomsData,
  onOpenViewGraph,
}: any) => {
  const firebase = useContext<any>(FirebaseContext);
  const deviceDialog = useDisclosure();
  const roomDialog = useDisclosure();
  const graphDialog = useDisclosure();
  const [selectedDevice, setSelectedDevice] = useState<any>();
  const [selectedModalTab, setSelectedModalTabName] = useState("control");
  const [command, setCommand] = useState();
  const [allIotStatusFb, setAllIotStatusFb] = useState<any>();
  const [energySummaryData] = useState<FloorEnergySummaryProps["energySummaryData"]>();

  const [roomInfoData, setRoomInfoData] = useState();

  const Ref: any = useRef(null);
  const [timer, setTimer] = useState(2);
  const [commandAc, setCommandAc] = useState<any>([]);

  const startTimer = (e: any) => {
    setTimer((prev: number) => {
      if (prev > 1) {
        return prev - 1;
      } else {
        setCommandAc((prev: any) => {
          sendDeviceControlCommandAc(
            selectedDevice.device_id,
            selectedDevice["subdevices"]["0"]["nickname"],
            { set_temperature: prev[prev.length - 1] },
            selectedDevice
          );
          return [];
        });
        clearInterval(Ref.current);
        return 0;
      }
    });
  };

  const clearTimer = (e: any) => {
    setTimer(2);
    if (Ref.current) {
      clearInterval(Ref.current);
    }
    const id = setInterval(() => {
      startTimer(e);
    }, 2000);
    Ref.current = id;
  };

  const onClickReset = (data: any) => {
    clearTimer(2);
    setCommandAc((prev: any) => {
      return [...prev, data];
    });
  };

  const checkLoading = (prev: any, data: any) => {
    if (data?.event_status && data?.total_waiting_command) {
      if (data?.event_status === "success" && data?.total_waiting_command === 0) {
        return { ...data, ["loading"]: false };
      } else {
        return prev;
      }
    } else {
      return data;
    }
  };

  const clearLoading = (deviceId: any, agentId?: string) => {
    firebase.db.ref(iot_devices_path + `/${deviceId}`).once("value", function (snap: { val: () => any }) {
      if (snap) {
        let data = snap.val();
        if (data) {
          // AC or OAU
          if (
            agentId === "bac0hvac" ||
            agentId === "itm_fcu" ||
            agentId === "itm_oau" ||
            agentId === "ac_wifi_adapter" ||
            agentId === "ac_wifi_adaptor"
          ) {
            setCommand(data?.ac?.subdev_0);
            setAllIotStatusFb((prev: any) => {
              const tempPrev = { ...prev };
              tempPrev[deviceId]["loading"] = false;
              return tempPrev;
            });
          }
        }
      }
    });
  };

  const fetchDeviceStatus = (didMount: boolean, deviceId: string, agentId?: string) => {
    if (didMount) {
      firebase.db.ref(iot_devices_path + `/${deviceId}`).off("value");
    } else {
      let tempDeviceId = agentId !== "camera" ? deviceId : deviceId.toLowerCase();
      firebase.db.ref(iot_devices_path + `/${tempDeviceId}`).on("value", function (snap: { val: () => any }) {
        if (snap) {
          let data = snap.val();
          if (data) {
            // AC or OAU
            if (
              agentId === "bac0hvac" ||
              agentId === "itm_fcu" ||
              agentId === "itm_oau" ||
              agentId === "ac_wifi_adapter" ||
              agentId === "ac_wifi_adaptor"
            ) {
              setCommand((prev) => checkLoading(prev, data?.ac?.subdev_0));
            }
            // Environment Sensor
            else if (agentId === "son_env" || agentId === "tuya_air_quality" || agentId === "tuya_env_relay" || agentId === "lorawan_env") {
              setCommand(data?.environment?.subdev_0);
            } else if (agentId === "tuya_temp_humid") {
              setCommand({ ...data?.environment?.subdev_0, battery_state: data?.device?.subdev_0?.battery_state });
            } else if (agentId === "netatmo") {
              setCommand(data?.environment?.subdev_1);
            } else if (agentId === "airveda") {
              setCommand(data?.environment?.subdev_0);
            }
            // Curtain
            else if (agentId === "tuyacloudcurtain" || agentId === "tuyablinds") {
              setCommand(data.device);
            }
            // Meter
            else if (agentId === "modbus") {
              if (buildingCode === "DAIR") {
                setCommand(data?.electric?.subdev_0);
              } else {
                setCommand(data?.electric?.subdev_3 ? data?.electric?.subdev_3 : data?.electric?.subdev_0);
              }
            }
            else if (agentId === "tuya_plug") {
              setCommand(data?.electric?.subdev_0);
            }
            // Others
            else if (agentId === "cctv" || agentId === "camera") {
              // setCommand(data?.camera_inout?.subdev_0);
              if (data?.camera_inout) {
                setCommand({ ...data?.camera_inout?.subdev_0, cameraType: "camera_inout" });
              } else if (data?.camera_crowdcount) {
                setCommand({ ...data?.camera_crowdcount?.subdev_0, cameraType: "camera_crowdcount" });
              }
            } else if (agentId === "tasmota") {
              setCommand(data.relay);
            }
          }
        }
      });
    }
  };

  const handleOpenModal = (e: any) => {
    fetchDeviceStatus(false, e.device_id, e.agent_id);
    setSelectedDevice(e);
    deviceDialog.onOpen();
  };

  const handleCloseModal = () => {
    fetchDeviceStatus(true, selectedDevice.device_id);
    deviceDialog.onClose();
    setCommand(undefined);
    setSelectedModalTabName("control");
  };

  const handleSelectTabName = (e: string) => {
    setSelectedModalTabName(e);
  };

  const sendDeviceControlCommand = async (device_id: string, subdevice_name: string, command: any, deviceData?: any) => {
    try {
      await postDeviceControl(device_id, subdevice_name, command, deviceData);
      toast.success("Successfully");
    } catch (error) {
      responseErrorHandler(error);
    }
  };

  const sendDeviceControlCommandAc = async (device_id: string, subdevice_name: string, command: any, deviceData?: any) => {
    try {
      await postDeviceControlAc(device_id, subdevice_name, command, deviceData);
      toast.success("Successfully");
    } catch (error) {
      responseErrorHandler(error);
      clearLoading(device_id, selectedDevice.agent_id);
    }
  };

  const changeFbData = (device_id: string, subdevice: string, key: string, value: any) => {
    if (allIotStatusFb) {
      setAllIotStatusFb((prev: any) => {
        const tempPrev = { ...prev };
        tempPrev[device_id]["loading"] = true;
        //TODO Change to use agent when ready
        if (tempPrev[device_id]["ac"]) {
          tempPrev[device_id]["ac"]["subdev_0"][key] = value;
          tempPrev[device_id]["ac"]["subdev_0"]["new_set_temperature"] = value;
        }
        if (tempPrev[device_id]["device"]) {
          tempPrev[device_id]["device"]["subdev_0"][key] = value;
          tempPrev[device_id]["device"]["subdev_0"]["new_percent_position"] = value;
        }
        return tempPrev;
      });
    }
  };

  const handleChangeCommand = (key: string, value: any, agent_id: any, subdevice: any) => {
    if (
      agent_id === "bac0hvac" ||
      agent_id === "itm_fcu" ||
      agent_id === "itm_oau" ||
      agent_id === "ac_wifi_adapter" ||
      agent_id === "ac_wifi_adaptor"
    ) {
      if (key === "set_temperature") {
        setCommand((prev: any) => {
          let tempPrev: any = { ...prev };
          if (value === "increase" && tempPrev[key] <= 30) {
            tempPrev[key] += 1;
          } else if (value === "decrease" && tempPrev[key] >= 19) {
            tempPrev[key] -= 1;
          }
          tempPrev["loading"] = true;
          changeFbData(selectedDevice.device_id, subdevice, key, tempPrev[key]);
          if (buildingCode === "BGM") {
            sendDeviceControlCommand(selectedDevice.device_id, subdevice, tempPrev, selectedDevice);
          } else {
            onClickReset(tempPrev[key]);
            // sendDeviceControlCommandAc(selectedDevice.device_id, subdevice, { [key]: tempPrev[key] }, selectedDevice);
          }
          return tempPrev;
        });
      } else if (key === "mode") {
        setCommand((prev: any) => {
          let tempPrev: any = { ...prev };
          tempPrev[key] = value;
          changeFbData(selectedDevice.device_id, subdevice, key, tempPrev[key]);
          if (buildingCode === "BGM") {
            sendDeviceControlCommand(selectedDevice.device_id, subdevice, tempPrev, selectedDevice);
          } else {
            sendDeviceControlCommandAc(selectedDevice.device_id, subdevice, { [key]: tempPrev[key] }, selectedDevice);
          }
          return tempPrev;
        });
      } else if (key === "fan") {
        setCommand((prev: any) => {
          let tempPrev: any = { ...prev };
          tempPrev[key] = value;
          changeFbData(selectedDevice.device_id, subdevice, key, tempPrev[key]);
          if (buildingCode === "BGM") {
            sendDeviceControlCommand(selectedDevice.device_id, subdevice, tempPrev, selectedDevice);
          } else {
            sendDeviceControlCommandAc(selectedDevice.device_id, subdevice, { [key]: tempPrev[key] }, selectedDevice);
          }
          return tempPrev;
        });
      } else if (key === "louver") {
        setCommand((prev: any) => {
          let tempPrev: any = { ...prev };
          tempPrev[key] = value;
          changeFbData(selectedDevice.device_id, subdevice, key, tempPrev[key]);
          if (buildingCode === "BGM") {
            sendDeviceControlCommand(selectedDevice.device_id, subdevice, tempPrev, selectedDevice);
          } else {
            sendDeviceControlCommandAc(selectedDevice.device_id, subdevice, { [key]: tempPrev[key] }, selectedDevice);
          }
          return tempPrev;
        });
      }
    } else if (agent_id === "tasmota" || agent_id === "itm_oau") {
      if (key === "state") {
        setCommand((prev: any) => {
          let tempPrev: any = { ...prev };
          const changedValue = value === "on" ? "off" : "on";
          tempPrev[subdevice][key] = changedValue;
          sendDeviceControlCommand(selectedDevice.device_id, subdevice, { [key]: changedValue }, selectedDevice);
          return tempPrev;
        });
      } else if (key === "mode") {
        setCommand((prev: any) => {
          let tempPrev: any = { ...prev };
          const changedValue = value === "on" ? "off" : "on";
          tempPrev[key] = changedValue;
          sendDeviceControlCommand(selectedDevice.device_id, subdevice, tempPrev, selectedDevice);
          return tempPrev;
        });
      }
    } else if (agent_id === "tuyacloudcurtain" || agent_id === "tuyablinds") {
      if (key === "control_state" || key === "percent_position") {
        sendDeviceControlCommand(selectedDevice.device_id, subdevice, { [key]: value }, selectedDevice);
        changeFbData(selectedDevice.device_id, "subdev_0", key, value);
      }
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      firebase.db.ref(iot_devices_path).once("value", function (snap: { val: () => any }) {
        let res = snap?.val();
        setAllIotStatusFb((prev: any) => {
          let newData = { ...res };
          let oldData = { ...prev };
          const deviceList = Object.keys(oldData);
          if (deviceList.length !== 0) {
            deviceList.forEach((deviceId: string) => {
              if (oldData?.[deviceId]?.["loading"] && oldData?.[deviceId]?.["loading"] === true) {
                if (oldData[deviceId]["ac"]) {
                  // Check ac
                  if (
                    oldData[deviceId]["ac"]["subdev_0"]["set_temperature"] === newData[deviceId]["ac"]["subdev_0"]["set_temperature"] &&
                    oldData[deviceId]["ac"]["subdev_0"]["mode"] === newData[deviceId]["ac"]["subdev_0"]["mode"] &&
                    oldData[deviceId]["ac"]["subdev_0"]["fan"] === newData[deviceId]["ac"]["subdev_0"]["fan"] &&
                    oldData[deviceId]["ac"]["subdev_0"]["louver"] === newData[deviceId]["ac"]["subdev_0"]["louver"]
                  ) {
                    oldData[deviceId] = newData[deviceId];
                  }
                } else if (oldData[deviceId]["device"]) {
                  // Check blind
                  try {
                    const newPosition = Number(newData[deviceId]["device"]["subdev_0"]["percent_position"]);
                    const oldPosition = Number(oldData[deviceId]["device"]["subdev_0"]["percent_position"]);
                    if (newPosition > oldPosition - 5 && newPosition < oldPosition + 5) {
                      oldData[deviceId] = newData[deviceId];
                    }
                  } catch (err) {
                    console.log(err);
                    oldData[deviceId] = newData[deviceId];
                  }
                }
              } else {
                oldData[deviceId] = newData[deviceId];
              }
            });
          } else {
            return newData;
          }
          return oldData;
        });
      });
    }, 1500);

    return () => clearInterval(interval);
  }, []);

  const handleSelectRoom = (roomInfo: any) => {
    setRoomInfoData(roomInfo);
    roomDialog.onOpen();
  };

  return (
    <>
      {selectedView === "fp" && (
        <Building
          deviceList={deviceList}
          selectedTab={selectedTab}
          handleOpenModal={handleOpenModal}
          visibleDeviceObject={visibleDeviceObject}
          buildingName={buildingName}
          allIotStatusFb={allIotStatusFb}
          buildingCode={buildingCode}
        />
      )}
      {/* ---------- Small View ---------- */}
      {selectedView === "s" && (
        <RoomsSection
          buildingCode={buildingCode}
          roomsData={roomsData}
          handleSelectRoom={handleSelectRoom}
          energySummaryData={energySummaryData}
          onOpenViewGraph={onOpenViewGraph}
        />
      )}
      <DeviceModal
        modalIsOpen={deviceDialog.isOpen}
        handleCloseModal={handleCloseModal}
        selectedTabName={selectedModalTab}
        handleSelectTabName={handleSelectTabName}
        deviceData={selectedDevice}
        command={command}
        handleChangeCommand={handleChangeCommand}
        allIotStatusFb={allIotStatusFb}
      />
      <RoomModal
        modalIsOpen={roomDialog.isOpen}
        handleCloseModal={handleCloseModal}
        selectedTabName={selectedModalTab}
        handleSelectTabName={handleSelectTabName}
        roomInfoData={roomInfoData}
        command={command}
        handleChangeCommand={handleChangeCommand}
        allIotStatusFb={allIotStatusFb}
      />
      <FloorGraphModal
        modalIsOpen={graphDialog.isOpen}
        handleCloseModal={handleCloseModal}
        selectedTabName={selectedModalTab}
        handleSelectTabName={handleSelectTabName}
        deviceData={selectedDevice}
        command={command}
        handleChangeCommand={handleChangeCommand}
        allIotStatusFb={allIotStatusFb}
      />
    </>
  );
};

export default RoomSelectionBody;
