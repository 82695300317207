import { AcControl } from "./AcControl";
import { BlindControl } from "./BlindControl";
import { EnvSensorControl } from "./EnvSensorControl";
import { SwitchControl } from "./SwitchControl";
import { CctvControl } from "./CctvControl";
import { GatewayControl } from "./GatewayControl";
import { SmartPlugRealtime } from "./SmartPlugRealtime";
import { OauControl } from "./OauControl";
import { MeterRealtime } from "./MeterRealtime";
import { EnvSensorTuyaControl } from "./EnvSensorTuyaControl";

export const DeviceControl = ({ allIotStatusFb, deviceData, handleChangeCommand, command }: any) => {
  return (
    <>
      {allIotStatusFb &&
        deviceData &&
        ((deviceData.agent_id === "bac0hvac" && !deviceData.device_id.includes('hrv')) ||
          deviceData.agent_id === "itm_fcu" ||
          deviceData.agent_id === "ac_wifi_adapter" ||
          deviceData.agent_id === "ac_wifi_adaptor") && (
          <>
            <AcControl
              command={allIotStatusFb[deviceData.device_id]?.["ac"]["subdev_0"]}
              handleChangeCommand={handleChangeCommand}
              deviceData={deviceData}
              loading={allIotStatusFb[deviceData.device_id]?.["loading"]}
            />
          </>
        )}
      {deviceData &&
        (deviceData.agent_id === "netatmo" ||
          deviceData.agent_id === "son_env" ||
          deviceData.agent_id === "tuya_env_relay" ||
          deviceData.agent_id === "tuya_air_quality" ||
          deviceData.agent_id === "airveda" ||
          deviceData.agent_id === "lorawan_env") && <EnvSensorControl command={command} />}
      {deviceData && deviceData.agent_id === "tuya_temp_humid" && <EnvSensorTuyaControl command={command} />}
      {deviceData && deviceData.agent_id === "tasmota" && (
        <SwitchControl command={command} deviceInfo={deviceData} handleChangeCommand={handleChangeCommand} />
      )}
      {(deviceData && deviceData.agent_id === "itm_oau"
        || (deviceData.agent_id === "bac0hvac"
          && deviceData.device_id.includes('hrv'))) && (
          <OauControl command={command} deviceInfo={deviceData} handleChangeCommand={handleChangeCommand} deviceData={deviceData} />
        )}
      {deviceData && (deviceData.agent_id === "cctv" || deviceData.agent_id === "camera") && (
        <CctvControl command={command} deviceInfo={deviceData} />
      )}
      {deviceData && deviceData.agent_id === "aigateway" && <GatewayControl command={command} />}
      {allIotStatusFb && deviceData && command && (deviceData.agent_id === "tuyacloudcurtain" || deviceData.agent_id === "tuyablinds") && (
        <BlindControl
          command={allIotStatusFb[deviceData.device_id]["device"]["subdev_0"]}
          handleChangeCommand={handleChangeCommand}
          deviceData={deviceData}
          loading={allIotStatusFb[deviceData.device_id]["loading"]}
          isOnline={allIotStatusFb[deviceData.device_id].device?.subdev_0?.online_status}
        />
      )}
      {deviceData && deviceData.agent_id === "tuya_plug" && <SmartPlugRealtime command={command} deviceData={deviceData} />}
      {deviceData && deviceData.agent_id === "modbus" && <MeterRealtime command={command} deviceData={deviceData} />}
    </>
  );
};
