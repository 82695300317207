import { useContext, useEffect, useState } from "react";
import { PageContainer } from "components/Common/components";
import FloorSection from "./FloorSection";
import SummarySection from "./SummarySection";
import { FirebaseContext } from "../../firebase";
import {
  co2_path,
  energy_saving_path,
  indoor_path,
  iot_devices,
  outdoor_path,
  EmptyDeviceData,
  total_energy_consumption_path,
  iot_devices_path,
  tooltips_path,
  daikin_camera,
  benchmark_rl_path,
  outdoor_path_v2,
} from "MockData/FirebasePath";
import { PieDataProps } from "components/Common/Graph/PieChart";
import {
  getEnergyConsumption,
  getEnergyConsumptionByFloor,
} from "services/api.service";
import { GraphModal } from "components/DeviceModal/GraphModal";
import { Col, Row } from "antd";
import {
  convertDatetimeToStrDate,
  convertDatetimeToStrMonth,
  getTomorrow,
} from "utils/convertDatetime";
import { responseErrorHandler } from "services/prepareAxios";
import {
  prepBarGraph,
  prepLineGraph,
  prepLineGraphModal,
  prepPieGraph,
} from "utils/prepGraphData";
import { useBuildingContext } from "layouts/BuildingProvider";
import modal_graph_map_by_building from "configs/modal_graph_map_by_building.json";
import real_time_power_map_by_building from "configs/real_time_power_map_by_building.json";
import real_time_power_map_by_floor from "configs/real_time_power_map_by_floor.json";
import building_config from "configs/building_config.json";
import energy_consumption_key from "configs/energy_consumption_key.json";
import { mapArrayToKey } from "utils/mapArrayValueToKey";
import { RightSection } from "./RightSection/RightSection";
import { off } from "process";
import { RiInformationLine } from "react-icons/ri";
import { IoCloseSharp } from "react-icons/io5";
import toast from "react-hot-toast";
import axios from "axios";

const realtimePowerMapByBuilding: any = real_time_power_map_by_building;
const realtimePowerMapByFloor: {
  [buildingCode: string]: {
    [floorName: string]: { key: string; x: string; y: string[] };
  };
} = real_time_power_map_by_floor;
const modalGraphMapByBuilding: any = modal_graph_map_by_building;
const floorByBuilding: {
  [buildingCode: string]: { floors: { label: string; value: string }[] };
} = building_config;
const energyConsumptionMap: any = energy_consumption_key;

const OverviewPage = () => {
  const { buildingCode, buildingName } = useBuildingContext();
  const firebase = useContext<any>(FirebaseContext);
  const [outdoorData, setOutdoorData] = useState();
  const [indoorData, setIndoorData] = useState();
  const [co2Data, setCo2Data] = useState({ this_month: "", accumulated: "" });
  const [energySavingData, setEnergySavingData] = useState({
    this_month: "",
    accumulated: "",
  });
  const [benchmarkRl, setBenchmarkRl] = useState({
    avg_energy: 0,
    percent: 0,
    updated_at: "",
  });
  const [iotDevices, setIotDevices] = useState(EmptyDeviceData);
  const [realtimePowerData, setRealtimePowerData] = useState<any>();
  const [realtimePowerConsumption, setRealtimePowerConsumption] =
    useState<any>();
  const [energyConsumptionData, setEnergyConsumptionData] = useState<
    PieDataProps[] | any
  >();
  const [energyConsumptionByDeviceType, setEnergyConsumptionByDeviceType] =
    useState<any>();
  const [energyConsumptionVsBaselineData, setEnergyConsumptionVsBaselineData] =
    useState<any>();
  const [dashboardData2, setDashboardData2] = useState<any>();
  const [totalEnergyConsumption, setTotalEnergyConsumption] = useState<any>();
  const [selectedTab, setSelectedTab] = useState("B");
  const [selectedGraphOpen, setSelectedGraphOpen] = useState(false);
  const [modalType, setModalType] = useState("floor_1");
  const [graphData, setGraphData] = useState<any>();
  const [graphDataModal, setGraphDataModal] = useState<any>();
  const [floorMap, setFloorMap] = useState<{ [key: string]: any }>(
    mapArrayToKey(floorByBuilding?.[buildingCode]?.["floors"], "label")
  );
  const [summaryData, setSummaryData] = useState({
    building_power_kW: [],
    floor_energy_kWh: [],
    human_count: [],
  });
  const [starttime] = useState(new Date());
  const [endtime] = useState(new Date());
  const [modalStarttime, setModalStarttime] = useState<{
    [modalType: string]: Date;
  }>({
    floor_1: new Date(),
    floor_2: new Date(),
    grid: new Date(),
    solar: new Date(),
    people: new Date(),
  });
  const [modalEndtime, setModalEndtime] = useState<{
    [modalType: string]: Date;
  }>({
    floor_1: getTomorrow(),
    floor_2: getTomorrow(),
    grid: getTomorrow(),
    solar: getTomorrow(),
    people: getTomorrow(),
  });
  const [tooltipObjectState, setTooltipObjectState] = useState<any>();

  const [daikinRdData, setdaikingRdData] = useState<any>();

  const [daikinCCCData, setdaikinCCCData] = useState<any>();

  //---------------------------1) MAIN---------------------------//
  const handleViewGraph = async (deviceData: any) => {
    setModalType(deviceData);
    setSelectedGraphOpen(true);
  };

  const search = async () => {
    let tempStarttime = convertDatetimeToStrDate(modalStarttime[modalType]);
    let tempEndtime = convertDatetimeToStrDate(modalEndtime[modalType]);
    try {
      setGraphDataModal((prev: any) => {
        const tempPrev = { ...prev };
        tempPrev[modalType] = undefined;
        return tempPrev;
      });
      let response;
      if (buildingCode === "DAIC") {
        // If buildingCode is "DAIC", use the specific API endpoint
        response = await axios.get(
          `https://altocerobackend.azurewebsites.net/api/v3.0/daikin_energy_consumption/get_energy_consumption/?type=dashboard&building_name=DaikinCCC&starttime=${tempStarttime}&endtime=${tempEndtime}`
        );
      } else {
        // Otherwise, use the default API call
        response = await getEnergyConsumption({
          type: "dashboard",
          building_name: buildingName,
          starttime: tempStarttime,
          endtime: tempEndtime,
        });
      }
      const result = response.data.results;
      const prepData: any = prepLineGraphModal(
        result,
        modalGraphMapByBuilding[buildingCode]
      );
      setGraphDataModal((prev: any) => {
        const tempPrev = { ...prev };
        tempPrev[modalType] = prepData[modalType];
        return tempPrev;
      });
    } catch (err) {
      setGraphDataModal((prev: any) => {
        const tempPrev = { ...prev };
        tempPrev[modalType] = null;
        return tempPrev;
      });
    }
  };

  const subscribeDevice = (
    didMount: boolean,
    tooltipKey: string,
    device_id: string,
    type?: string,
    schema?: string,
    subdevice?: string,
    subdeviceKey?: string,
    hotel_code?: any
  ) => {
    if (didMount) {
      firebase.db.ref(iot_devices_path + "/" + device_id).off("value");
    } else {
      firebase.db
        .ref(iot_devices_path + "/" + device_id)
        .on("value", function (snap: { val: () => any }) {
          if (snap) {
            const value = snap.val();
            if (type === "camera") {
              const numberOfPeople = Number(
                value?.camera_inout?.subdev_0?.count_people_in -
                  value?.camera_inout?.subdev_0?.count_people_out
              );
              setDashboardData2((prev: any) => {
                let tempPrev = { ...prev };
                if (tempPrev[tooltipKey]["items"][device_id]) {
                  tempPrev[tooltipKey]["items"][device_id]["value"] =
                    numberOfPeople;
                  return tempPrev;
                }
                return tempPrev;
              });
            } else {
              if (value && schema && subdevice && subdeviceKey) {
                let power = Number(
                  value[schema][subdevice][subdeviceKey].toFixed(2)
                );
                const ts = new Date(value[schema][subdevice]["timestamp"]);
                const tsString = ts.getHours() + ":" + ts.getMinutes();
                setDashboardData2((prev: any) => {
                  let tempPrev = { ...prev };
                  tempPrev[tooltipKey]["items"][device_id]["value"] = power;
                  tempPrev[tooltipKey]["items"][device_id]["timestamp"] =
                    tsString;
                  return tempPrev;
                });
              }
            }
          }
        });
    }
  };

  const fetchDaikinRD = async (didMount: boolean) => {
    if (!didMount) {
      firebase.db.ref(iot_devices_path).off("value");
    } else {
      firebase.db
        .ref(iot_devices_path)
        .on("value", function (snap: { val: () => any }) {
          if (snap) {
            const value = snap.val();
            setdaikingRdData(value);
          }
        });
    }
  };

  const fetchDaikinCCC = async (didMount: boolean) => {
    if (!didMount) {
      firebase.db.ref(iot_devices_path).off("value");
    } else {
      firebase.db
        .ref(iot_devices_path)
        .on("value", function (snap: { val: () => any }) {
          if (snap) {
            const value = snap.val();
            setdaikinCCCData(value);
          }
        });
    }
  };

  useEffect(() => {
    if (buildingCode === "DAIR") {
      fetchDaikinRD(true);
    }
    return () => {
      fetchDaikinRD(false);
    };
  }, []);

  useEffect(() => {
    if (buildingCode === "DAIC") {
      fetchDaikinCCC(true);
    }
    return () => {
      fetchDaikinCCC(false);
    };
  }, []);

  // Daikin CCC extract
  function processDeviceStatus({
    deviceType,
    deviceMapping,
    datas,
    output,
    title,
    useTimestamp = false,
  }: any) {
    let online = 0;
    let offline = 0;
    let noneCount = 0;
    const devices = deviceMapping?.[deviceType] || {};

    for (const deviceKey in devices) {
      const deviceData = datas?.[devices?.[deviceKey]] || {};
      if (deviceData) {
        if (useTimestamp) {
          const deviceTimestamp =
            deviceData.camera_inout?.subdev_0?.unix_timestamp;

          if (deviceTimestamp) {
            const currentTimestamp = Math.floor(Date.now() / 1000); // Current timestamp in seconds
            if (currentTimestamp - deviceTimestamp < 60 * 60) {
              online++;
            } else {
              offline++;
            }
          } else {
            noneCount++;
          }
        } else if (deviceType === "AC") {
          const deviceStatus = deviceData.ac?.subdev_0?.mode;
          if (deviceStatus) {
            if (deviceStatus != "off") {
              online++;
            } else {
              offline++;
            }
          } else {
            noneCount++;
          }
        } else {
          const deviceStatus = deviceData.device?.subdev_0?.device_status;
          if (deviceStatus) {
            if (deviceStatus === "online") {
              online++;
            } else {
              offline++;
            }
          } else {
            noneCount++;
          }
        }
      }
    }

    output[deviceType.toLowerCase()] = {
      none: noneCount,
      offline: offline,
      online: online,
      title: title,
      total: offline + online,
    };
  }
  // end extract

  let daikinCCC = {};

  processDeviceStatus({
    deviceType: "AC",
    deviceMapping: deviceMapping,
    datas: daikinCCCData,
    output: daikinCCC,
    title: "FCU",
  });
  processDeviceStatus({
    deviceType: "Blind",
    deviceMapping: deviceMapping,
    datas: daikinCCCData,
    output: daikinCCC,
    title: "Blind",
  });
  processDeviceStatus({
    deviceType: "CCTV",
    deviceMapping: deviceMapping,
    datas: daikinCCCData,
    output: daikinCCC,
    title: "CCTV",
    useTimestamp: true,
  }); // Note: Use true for useTimestamp
  processDeviceStatus({
    deviceType: "EnvironmentSensor",
    deviceMapping: deviceMapping,
    datas: daikinCCCData,
    output: daikinCCC,
    title: "Environment Sensor",
  });
  processDeviceStatus({
    deviceType: "OAU",
    deviceMapping: deviceMapping,
    datas: daikinCCCData,
    output: daikinCCC,
    title: "OAU",
  });

  function extractData(data: any) {
    const result: any = {
      fcu: [],
      hrv: [],
      environment: [],
      camera: [],
      other: [],
    };

    for (const deviceKey in data) {
      const device = data[deviceKey];
      if (device.ac) {
        for (const subDeviceKey in device.ac) {
          const subDevice = device.ac[subDeviceKey];
          if (subDevice.ac_type && ["fcu", "hrv"].includes(subDevice.ac_type)) {
            result[subDevice.ac_type]
              ? result[subDevice.ac_type].push(subDevice)
              : result["other"].push(subDevice);
          } else {
            result["other"].push(subDevice);
          }
        }
      } else if ("environment" in data[deviceKey]) {
        result["environment"].push(data[deviceKey]);
      } else if (deviceKey.includes("cam")) {
        result["camera"].push(device);
      } else {
        result["other"].push(device);
      }
    }

    return result;
  }

  const result = extractData(daikinRdData);

  function countFCU(data: any) {
    let count = 0;

    data.map((device: any) => {
      if (device.on) {
        count++;
      }
    });

    return count;
  }

  function countHRV(data: any) {
    let count = 0;

    data.map((device: any) => {
      if (device.mode === "on") {
        count++;
      }
    });

    return count;
  }

  function countEnvironment(data: any) {
    let count = 0;

    data.map((item: any) => {
      if (item.device.subdev_0.online_status) {
        count++;
      }
    });

    return count;
  }

  const fcuCount = countFCU(result?.fcu);
  const hrvCount = countHRV(result?.hrv);
  const environmentCount = countEnvironment(result?.environment);
  const camCount = countDeviceStatus(result?.camera);

  function countDeviceStatus(cameras: any) {
    const currentTimeUTC = new Date().getTime() / 1000; // Convert current time to UTC in seconds
    let onlineCount = 0;
    let offlineCount = 0;

    cameras.forEach((camera: any) => {
      const cameraTypes = ["camera_crowdcount", "camera_inout"];
      cameraTypes.forEach((type) => {
        if (camera[type]) {
          for (const subDeviceKey in camera[type]) {
            const subDevice = camera[type][subDeviceKey];
            let isOffline = false;
            for (const cameraKey in subDevice) {
              const cameraDetails = subDevice[cameraKey];
              // Adjusting to consider the provided data structure
              const cameraTime = cameraDetails.unix_timestamp;
              const timeDifference = currentTimeUTC - cameraTime;
              if (!isNaN(timeDifference) && timeDifference > 3600) {
                // 3600 seconds = 1 hour
                isOffline = true;
                break; // Break to ensure we exit the loop if one camera is offline
              }
            }
            if (isOffline) {
              offlineCount++;
            } else {
              onlineCount++;
            }
          }
        }
      });
    });
    return { onlineCount, offlineCount };
  }

  function countAllStatus(result: any) {
    let resultData = {
      ac: {
        none: 0,
        offline: result.fcu.length - fcuCount,
        online: fcuCount,
        title: "FCU",
        total: result.fcu.length,
      },
      cctv: {
        none: 0,
        offline: result.camera.length - camCount.onlineCount,
        online: camCount.onlineCount,
        title: "CCTV",
        total: result.camera.length,
      },
      environment_sensor: {
        none: 0,
        offline: result.environment.length - environmentCount,
        online: environmentCount,
        title: "Environment Sensor",
        total: result.environment.length,
      },
      hrv: {
        none: 0,
        offline: result.hrv.length - hrvCount,
        online: hrvCount,
        title: "HRV",
        total: result.hrv.length,
      },
    };

    return resultData;
  }

  const daikinRD = countAllStatus(result);

  function fetchFbData(didMount: boolean, buildingCode?: string) {
    if (didMount) {
      firebase.db.ref(total_energy_consumption_path).off("value");
      firebase.db.ref(outdoor_path).off("value");
      firebase.db.ref(indoor_path).off("value");
      firebase.db.ref(iot_devices).off("value");
      firebase.db.ref(co2_path).off("value");
      firebase.db.ref(energy_saving_path).off("value");
      firebase.db.ref(daikin_camera["camera1"]).off("value");
      firebase.db.ref(daikin_camera["camera2"]).off("value");
      firebase.db.ref(daikin_camera["camera3"]).off("value");
      firebase.db.ref(daikin_camera["camera4"]).off("value");
      if (tooltipObjectState) {
        Object.entries(tooltipObjectState).forEach(
          ([tooltipKey, tooltipValue]: any) => {
            Object.entries(tooltipValue.items).forEach(
              ([deviceId, value]: any) => {
                if (value.device_id) {
                  subscribeDevice(
                    true,
                    tooltipKey,
                    value.device_id,
                    buildingCode
                  );
                }
              }
            );
          }
        );
      }
    } else {
      firebase.db
        .ref(total_energy_consumption_path)
        .on("value", function (snap: { val: () => any }) {
          if (snap) {
            setTotalEnergyConsumption(snap.val());
          }
        });
      firebase.db
        .ref(buildingCode === "DAIR" ? outdoor_path_v2 : outdoor_path)
        .on("value", function (snap: { val: () => any }) {
          if (snap) {
            setOutdoorData(snap.val());
          }
        });
      firebase.db
        .ref(indoor_path)
        .on("value", function (snap: { val: () => any }) {
          if (snap) {
            setIndoorData(snap.val());
          }
        });
      firebase.db
        .ref(iot_devices)
        .on("value", function (snap: { val: () => any }) {
          if (snap) {
            setIotDevices(snap.val());
          }
        });
      firebase.db
        .ref(co2_path)
        .on("value", function (snap: { val: () => any }) {
          if (snap) {
            setCo2Data(snap.val());
          }
        });
      firebase.db
        .ref(energy_saving_path)
        .on("value", function (snap: { val: () => any }) {
          if (snap) {
            setEnergySavingData(snap.val());
          }
        });
      firebase.db
        .ref(benchmark_rl_path)
        .on("value", function (snap: { val: () => any }) {
          if (snap) {
            setBenchmarkRl(snap.val());
          }
        });
      if (buildingCode === "DAIC") {
        ["camera1", "camera2", "camera3", "camera4"].forEach(
          (cameraName: string) => {
            firebase.db
              .ref(daikin_camera["cameraName"])
              .on("value", function (snap: { val: () => any }) {
                if (snap) {
                  const value = snap.val();
                  const numberOfPeople = Number(
                    value?.count_people_in - value?.count_people_out
                  );
                  setDashboardData2((prev: any) => {
                    let tempPrev = { ...prev };
                    if (tempPrev?.people?.items?.camera1) {
                      tempPrev["people"]["items"]["cameraName"]["value"] =
                        numberOfPeople;
                      return tempPrev;
                    }
                    return tempPrev;
                  });
                }
              });
          }
        );
      }
      firebase.db
        .ref(tooltips_path)
        .once("value", function (snap: { val: () => any }) {
          if (snap) {
            const tooltipObject = snap.val();
            setDashboardData2(tooltipObject);
            if (tooltipObject) {
              setTooltipObjectState(tooltipObject);
              Object.entries(tooltipObject).forEach(
                ([tooltipKey, tooltipValue]: any) => {
                  Object.entries(tooltipValue.items).forEach(
                    ([deviceId, value]: any) => {
                      if (value.device_id) {
                        subscribeDevice(
                          false,
                          tooltipKey,
                          value.device_id,
                          tooltipValue.type,
                          value.schema,
                          value.subdevice,
                          value.subdevice_key,
                          buildingCode
                        );
                      }
                    }
                  );
                }
              );
            }
          }
        });
    }
  }

  const fetchEnergyConsumptionBuilding = async (buildingCode: string) => {
    let today = new Date();
    let tempStarttime = convertDatetimeToStrDate(today);
    let tempEndtime = convertDatetimeToStrDate(today, "tomorrow");
    try {
      let response;
      if (buildingCode === "DAIC") {
        // If buildingCode is "DAIC", use the specific API endpoint
        response = await axios.get(
          `https://altocerobackend.azurewebsites.net/api/v3.0/daikin_energy_consumption/get_energy_consumption/?type=dashboard&building_name=DaikinCCC&starttime=${tempStarttime}&endtime=${tempEndtime}`
        );
      } else {
        // Otherwise, use the default API call
        response = await getEnergyConsumption({
          type: "dashboard",
          building_name: buildingName,
          starttime: tempStarttime,
          endtime: tempEndtime,
        });
      }
      const baselineConfig =
        buildingCode === "DAIC"
          ? ["power_load_fl1", "baseline"]
          : ["power_load_est"];
      let result = response.data.results;
      // 1) Set summary graph
      setSummaryData(result);
      setRealtimePowerData(
        prepLineGraph(
          result.building_power_kW,
          realtimePowerMapByBuilding[buildingCode],
          "timestamp"
        )
      );
      setEnergyConsumptionVsBaselineData(
        prepLineGraph(result.building_power_kW, baselineConfig, "timestamp")
      );
      setEnergyConsumptionData(
        buildingCode === "DAIR"
          ? {
              keys: ["floor_1", "floor_2", "floor_3"],
              indexBy: ["timestamp"],
              data: result?.energy_hourly_by_floor.map((item: any) => ({
                ...item,
                timestamp: item.timestamp?.substring(11, 16),
              })),
              legend: "timestamp",
            }
          : buildingCode === "SHE"
          ? {
              data: mockEnergySavingVsPacback,
              keys: ["energy"],
              indexBy: ["type"],
            }
          : prepPieGraph(result, buildingCode)
      );
      setEnergyConsumptionByDeviceType(
        buildingCode === "DAIR"
          ? {
              keys: ["HVAC Load", "Plug Load", "CDU Load"],
              indexBy: ["timestamp"],
              data: result?.device_type_energy_kWh.map((item: any) => ({
                ...item,
                timestamp: item.timestamp?.substring(11, 16),
              })),
              legend: "timestamp",
            }
          : {
              keys: ["energy"],
              indexBy: ["type"],
              data: result?.device_type_energy_kWh?.map((item: any) => ({
                ...item,
                energy: Math.round(item.energy * 100) / 100,
              })),
              legend: "device_type",
            }
      );
      // 2) Set modal graph
      setGraphData(
        prepLineGraphModal(result, modalGraphMapByBuilding[buildingCode])
      );
      setGraphDataModal(
        prepLineGraphModal(result, modalGraphMapByBuilding[buildingCode])
      );
      let powerConsByFloor: { [floorName: string]: any } = {};
      Object.entries(realtimePowerMapByFloor[buildingCode]).forEach(
        ([floorName, val]: any) => {
          powerConsByFloor[floorName] = {
            realtimePowerConsumption: prepLineGraph(
              result[val.key],
              val.y,
              val.x
            ),
          };
        }
      );
      setRealtimePowerConsumption(powerConsByFloor);
    } catch (err) {
      setGraphData(null);
      setRealtimePowerData(null);
      setEnergyConsumptionVsBaselineData(null);
      setEnergyConsumptionData(null);
      setRealtimePowerConsumption({
        F1: null,
        F2: null,
      });
      responseErrorHandler(err);
    }
  };

  useEffect(() => {
    setFloorMap(
      mapArrayToKey(floorByBuilding[buildingCode]["floors"], "label")
    );
    fetchEnergyConsumptionBuilding(buildingCode);
    fetchFbData(false, buildingCode);
    return () => {
      fetchFbData(true);
    };
    // eslint-disable-next-line
  }, []);
  //-------------------------END MAIN--------------------------//

  //---------------------------2) FLOOR---------------------------//
  const fetchEnergyConsByFloor = async (selectedTab: string) => {
    try {
      const dailyRequest = getEnergyConsumptionByFloor({
        type: "floor_energy",
        building_name: buildingName,
        starttime: convertDatetimeToStrDate(starttime, -6),
        endtime: convertDatetimeToStrDate(endtime),
        floor_name: floorMap[selectedTab]["value"],
        sample: "daily",
      });
      const monthlyRequest = getEnergyConsumptionByFloor({
        type: "floor_energy",
        building_name: buildingName,
        starttime: convertDatetimeToStrMonth(starttime, -2),
        endtime: convertDatetimeToStrMonth(endtime),
        floor_name: floorMap[selectedTab]["value"],
        sample: "monthly",
      });
      const [dailyResponse, monthlyResponse] = await Promise.all([
        dailyRequest,
        monthlyRequest,
      ]);
      if (realtimePowerConsumption[selectedTab]) {
        setRealtimePowerConsumption((prev: any) => {
          const tempPrev: any = { ...prev };
          tempPrev[selectedTab]["dailyEnergyConsumption"] = prepBarGraph(
            dailyResponse?.data?.floor_data,
            energyConsumptionMap[selectedTab]["kWh"],
            "date"
          );
          tempPrev[selectedTab]["monthlyEnergyConsumption"] = prepBarGraph(
            monthlyResponse?.data?.floor_data,
            energyConsumptionMap[selectedTab]["kWh"],
            "date"
          );
          tempPrev[selectedTab]["co2"] = {
            daily:
              dailyResponse?.data?.floor_data[
                dailyResponse?.data?.floor_data?.length - 1
              ][energyConsumptionMap[selectedTab]["co2"]],
            monthly:
              monthlyResponse?.data?.floor_data[
                monthlyResponse?.data?.floor_data?.length - 1
              ][energyConsumptionMap[selectedTab]["co2"]],
          };
          return tempPrev;
        });
      }
    } catch (err) {
      responseErrorHandler(err);
      setRealtimePowerConsumption({
        [selectedTab]: {
          realtimePowerConsumption: null,
          dailyEnergyConsumption: null,
          monthlyEnergyConsumption: null,
          co2: null,
        },
      });
    }
  };

  useEffect(() => {
    if (selectedTab !== "B") {
      fetchEnergyConsByFloor(selectedTab);
    }
  }, [selectedTab]);
  //------------------------END FLOOR------------------------//

  //--------------------------3) MODAL--------------------------//
  const onChangeModalStarttime = (time: Date) => {
    setModalStarttime((prev: any) => ({ ...prev, [modalType]: time }));
  };

  const onChangeModalEndtime = (time: Date) => {
    setModalEndtime((prev: any) => {
      let tempPrev = { ...prev };
      tempPrev[modalType] = time;
      return tempPrev;
    });
  };

  useEffect(() => {
    if (selectedGraphOpen) {
      search();
    }
    const isRefreshed = localStorage.getItem("isRefreshed");
    if (isRefreshed !== "true") {
      localStorage.setItem("isRefreshed", "true");
      window.location.reload();
    }
  }, [modalStarttime, modalEndtime]);
  //------------------------END MODAL------------------------//
  //--------------------------4) Toast survey--------------------------//

  const showToast = () => {
    toast.custom(
      (t) => (
        <div
          className="bg-white border border-[#DBE4FF] p-4 rounded-[14px] shadow-alert"
          style={{
            position: "fixed",
            top: "0px", // Adjust this value to change the distance from the top
            right: "10px", // Adjust this value to change the distance from the left
            width: "463px",
            boxShadow: "1px 3px 10px 0px rgba(57, 124, 221, 0.3)",
            borderRadius: "14px",
            zIndex: 9998,
          }}
        >
          <div className="flex items-start">
            <RiInformationLine size={32} className="text-black2 -mt-1.5" />
            <div className="ml-2">
              <p className="text-black2 font-medium text-[12px]">
                แบบสำรวจผู้ใช้งาน
              </p>
              <p className="text-primary-dark text-[15px] mt-[6px]">
                เพื่อให้เราสามารถปรับปรุงระบบ ของเราให้ดียิ่งขึ้นต่อไป
                ขอความกรุณาตอบแบบสำรวจสั้นๆนี้และแจ้งให้เราทราบถึงความคิดของคุณ
              </p>
              <div
                className="flex flex-row-reverse mt-3"
                style={{ display: "flex", flexDirection: "row-reverse" }}
              >
                <button
                  className="text-primary-dark text-[12px] font-medium leading-[18px]"
                  style={{
                    border: "2px solid #0E7EE4",
                    borderRadius: "5px",
                    paddingLeft: "6px",
                    paddingRight: "6px",
                    paddingTop: "1px",
                    paddingBottom: "1px",
                  }}
                  onClick={() =>
                    window.open("https://forms.gle/1odR4ZPpaeKXr3tK6", "_blank")
                  }
                >
                  เริ่มต้นทำแบบสำรวจ
                </button>
              </div>
            </div>
            <IoCloseSharp
              className="cursor-pointer ml-10 w-8 h-8"
              onClick={() => toast.dismiss(t.id)}
            />
          </div>
        </div>
      ),
      { duration: 10000, position: "top-right" }
    );
  };

  // useEffect(() => {
  //   // const hasShownToast = localStorage.getItem("hasShownToast");
  //   // if (!hasShownToast) {
  //   //   showToast();
  //   //   localStorage.setItem("hasShownToast", "true");
  //   // }
  //   showToast();
  // }, [totalEnergyConsumption]);

  return (
    <>
      <PageContainer>
        <Row gutter={[18, 18]}>
          <Col xs={18}>
            <Row gutter={[18, 18]}>
              <Col xs={24} style={{ zIndex: 9999 }}>
                <FloorSection
                  selectedTab={selectedTab}
                  setSelectedTab={setSelectedTab}
                  dashboardData2={dashboardData2}
                  totalEnergyConsumption={totalEnergyConsumption}
                  handleViewGraph={handleViewGraph}
                  siteName={buildingName}
                  buildingCode={buildingCode}
                  floorMap={floorMap}
                />
              </Col>
              <Col xs={24}>
                <SummarySection
                  realtimePowerData={realtimePowerData}
                  energyConsumptionData={energyConsumptionData}
                  energyConsumptionVsBaselineData={
                    energyConsumptionVsBaselineData
                  }
                  graphData={graphData}
                  summaryData={summaryData}
                  buildingCode={buildingCode}
                  energyConsumptionByDeviceType={energyConsumptionByDeviceType}
                />
              </Col>
            </Row>
          </Col>
          <Col xs={6}>
            {selectedTab === "B" && buildingCode === "DAIR" ? (
              <RightSection
                iotDevices={daikinRD}
                outdoorData={outdoorData}
                indoorData={indoorData}
                dashboardData={dashboardData2}
                siteName={buildingName}
                energySaving={energySavingData}
                benchmarkRl={benchmarkRl}
                co2Emission={co2Data}
              />
            ) : buildingCode === "DAIC" ? (
              <RightSection
                iotDevices={daikinCCC}
                outdoorData={outdoorData}
                indoorData={indoorData}
                dashboardData={dashboardData2}
                siteName={buildingName}
                energySaving={energySavingData}
                benchmarkRl={benchmarkRl}
                co2Emission={co2Data}
              />
            ) : (
              <RightSection
                iotDevices={iotDevices}
                outdoorData={outdoorData}
                indoorData={indoorData}
                dashboardData={dashboardData2}
                siteName={buildingName}
                energySaving={energySavingData}
                benchmarkRl={benchmarkRl}
                co2Emission={co2Data}
              />
            )}
          </Col>
        </Row>
        <GraphModal
          buildingCode={buildingCode}
          selectedGraphOpen={selectedGraphOpen}
          floorName={selectedTab}
          closeModal={() => setSelectedGraphOpen(false)}
          starttime={modalStarttime[modalType]}
          setStarttime={onChangeModalStarttime}
          endtime={modalEndtime[modalType]}
          setEndtime={onChangeModalEndtime}
          modalType={modalType}
          data={graphDataModal}
          search={search}
        />
      </PageContainer>
    </>
  );
};

export default OverviewPage;

const mockEnergySavingVsPacback = [
  {
    energy: 18.4,
    type: "Self-driving",
    unit: "kWh",
  },
  {
    energy: 27.0,
    type: "Baseline",
    unit: "kWh",
  },
];

const deviceMapping = {
  AC: [
    "DaikinCCC-FCU-1-01",
    "DaikinCCC-FCU-1-02",
    "DaikinCCC-FCU-1-03",
    "DaikinCCC-FCU-1-04",
    "DaikinCCC-FCU-1-05",
    "DaikinCCC-FCU-1-06",
    "DaikinCCC-FCU-1-07",
    "DaikinCCC-FCU-1-08",
    "DaikinCCC-FCU-1-09",
    "DaikinCCC-FCU-1-10",
    "DaikinCCC-FCU-1-11",
    "DaikinCCC-FCU-1-12",
    "DaikinCCC-FCU-1-13",
    "DaikinCCC-FCU-1-14",
    "DaikinCCC-FCU-1-15",
    "DaikinCCC-FCU-1-16",
    "DaikinCCC-FCU-1-17",
    "DaikinCCC-FCU-1-18",
    "DaikinCCC-FCU-1-19",
    "DaikinCCC-FCU-1-20",
    "DaikinCCC-FCU-1-21",
    "DaikinCCC-FCU-1-22",
    "DaikinCCC-FCU-2-01",
    "DaikinCCC-FCU-2-02",
    "DaikinCCC-FCU-2-03",
    "DaikinCCC-FCU-2-04",
    "DaikinCCC-FCU-2-05",
    "DaikinCCC-FCU-2-06",
    "DaikinCCC-FCU-2-07",
    "DaikinCCC-FCU-2-08",
    "DaikinCCC-FCU-2-09",
    "DaikinCCC-FCU-2-10",
    "DaikinCCC-FCU-2-11",
    "DaikinCCC-FCU-2-12",
    "DaikinCCC-FCU-2-13",
    "DaikinCCC-FCU-2-14",
    "DaikinCCC-FCU-2-15",
    "DaikinCCC-FCU-2-16",
    "DaikinCCC-FCU-2-17",
    "DaikinCCC-FCU-2-18",
    "DaikinCCC-FCU-2-19",
    "DaikinCCC-FCU-2-20",
  ],
  Blind: [
    "00660024a4e57c9651f6",
    "36100710bcff4d4fd36a",
    "00660024a4e57c968515",
    "00660024a4e57c13f74b",
    "11531080d8f15b12e1c8",
    "36100710bcff4d4e689e",
    "11531080d8f15b1386b8",
    "36100710bcff4d4e673b",
    "36100710bcff4d4edb88",
    "11531080d8f15b12eac5",
    "36100710bcff4d4fcae3",
    "00660024a4e57c967b25",
    "00660024a4e57c9651ed",
    "00660024a4e57c965350",
    "36100710bcff4d4ffb64",
    "36100710bcff4d4e8fcb",
    "36100710bcff4d4ff2fa",
    "36100710bcff4d4e6975",
    "36100710bcff4d4e6b95",
    "36100710bcff4d4f8ddf",
  ],
  CCTV: [
    "44:ef:bf:a0:14:4c",
    "44:ef:bf:9f:dd:71",
    "44:ef:bf:a0:09:c2",
    "44:ef:bf:a0:0b:af",
  ],
  EnvironmentSensor: [
    "1210210009",
    "ebd57f4fd41b31dd1fdd7o",
    "eb7ca712c672610d99z8bw",
    "ebd82875334b345a7ayn6n",
    "ebacee8a441fba9d05xx84",
    "ebda07d30ee6be7c13zbbd",
    "eba1f8ea2aebc71603y9ro",
    "eb266cba7d62b9ac0a8pwm",
    "eb7a71c9fc4f7837c6wxjg",
    "ebd12b7177d5e31186exf3",
    "eb91020bbf1dc377e5bxb4",
    "eb469134c83d806bc2xk86",
    "eb2f60193ac6f76171pywj",
    "eba7cec590ad860668uqgv",
    "ebaf51dd6ea7174066jeba",
    "eb2cc75154a2c70a46ioig",
    "ebd06f07a5e35f0d89cft7",
    "eb356f6201665705e4cr36",
    "eb00db71cc034d77f2nguw",
    "ebab65495c6233a3ecfex1",
    "ebcfad00ae7ffacf5coyrl",
    "eb58b62396473a1287ojam",
    "ebea85d39f4272b175clhh",
    "eb1c9f10dd34b30c6avo4z",
    "ebf8c6e6d4131e1bcc2cz0",
    "eb2e93a68731a6065bidbr",
    "ebb7a6858d98bc93f3jmo0",
    "eb22c2f93f0393c380cf16",
    "eb2e42fe69d3bcee98axc9",
    "eb1628669cbd8ee0e8strx",
    "eb8fe64b268e5ebccdvs64",
    "eb5e4c3fbff6557cc8rsea",
    "ebf283f908e050c46axtaw",
    "eb9703dbc6bea37d77d57z",
    "ebf8783eba7db698a9zc53",
    "eb3b3f106dc87c7b01jvp4",
    "eb918ff563509b9c10zdki",
  ],
  OAU: [
    "DaikinCCC-OAU-1-01",
    "DaikinCCC-OAU-2-01",
    "DaikinCCC-OAU-2-02",
    "DaikinCCC-OAU-2-03",
    "DaikinCCC-DESSICA-1-01",
    "DaikinCCC-DESSICA-1-02",
  ],
};
