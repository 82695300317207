import styled from "styled-components";
import Frame2 from "assets/pages/Dashboard/Performance/Frame2.svg";
import Arrow from "assets/pages/Dashboard/Performance/Indicator.svg";
import UnionSvg from "assets/pages/Dashboard/Performance/Union.svg";
import Circle from "assets/pages/Dashboard/Circle.svg";
import tw from "twin.macro";

interface typeComponent {
  positionArrow: { positionArrow: number };
  unionPosition: { unionPosition: number };
  labelPosition: { labelPosition: number };
  labelPercent: { labelPercent: number };
  dataDashboard: {
    totalEnergyConsumption: {
      value: number;
      updated_at: number;
    };
  };
}

const ArrowComponent = styled.img<typeComponent["positionArrow"]>`
  position: absolute;
  top: -20%;
  left: ${(prop) => prop.positionArrow + 5}%;
`;

const Union = styled.img<typeComponent["unionPosition"]>`
  position: absolute;
  top: -100%;
  left: ${(prop) => prop.unionPosition + 2}%;
`;

const UnionLabel = styled.div<typeComponent["labelPosition"]>`
  position: absolute;
  top: -85%;
  font-size: 12px;
  font-weight: 600;
  left: ${(prop) => prop.labelPosition + 7}%;
`;

const CircleEnergyContainer = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 140px;
  height: 140px;
  display: grid;
  background: url(${Circle}) center no-repeat;
  background-size: 100% 100%;
  top: 0px;
  right: 0px;
`;

const EnergyValue = styled.p`
  font-size: 28px;
  text-align: center;
  margin: 0px;
  margin-top: -16px;
`;

const UnitContainer = tw.div`grid grid-cols-1 divide-y h-4 mt-[-4px]`;

const UnitString = tw.small`text-[9px] text-center`;

const Unit = () => {
  return (
    <UnitContainer>
      <UnitString>kWh</UnitString>
      <UnitString>m2⋅year</UnitString>
    </UnitContainer>
  );
};

const BuildingPerformance = ({
  totalEnergyConsumption,
}: typeComponent["dataDashboard"]) => {
  const convertLeftPosition = (val: number) => {
    let min = 0;
    let bec = 171;
    let heps = 141;
    let econ = 82;
    let zeb = 57;
    if (val > 171) {
      return { position: min, zone: "BEC" };
    } else if (val > 141) {
      return { position: min + val / (bec - heps), zone: "BEC" };
    } else if (val > 82) {
      return { position: 22 + val / (heps - econ), zone: "HEPS" };
    } else if (val > 57) {
      return { position: 42 + val / (econ - zeb), zone: "ECON" };
    } else {
      return { position: 62 + val / (zeb - 0), zone: "ZEB" };
    }
  };

  let value = totalEnergyConsumption ? totalEnergyConsumption.value : 0;

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        marginTop: "3.8em",
        marginBottom: "1em",
        position: "relative",
      }}
    >
      <ArrowComponent
        src={Arrow}
        positionArrow={convertLeftPosition(value).position}
        // positionArrow={67}
      />
      <Union
        src={UnionSvg}
        alt="union"
        unionPosition={convertLeftPosition(value).position}
        // unionPosition={67}
      />
      <UnionLabel
        labelPosition={convertLeftPosition(value).position}
        // labelPosition={67}
      >
        {convertLeftPosition(value).zone}
        {/* ZEB */}
      </UnionLabel>
      <img src={Frame2} alt="scale" width="100%" />
    </div>
  );
};

const CircleEnergyPerformance = ({
  totalEnergyConsumption,
}: typeComponent["dataDashboard"]) => {
  let value = totalEnergyConsumption
    ? Number(totalEnergyConsumption.value).toFixed(1)
    : 0;
  return (
    <CircleEnergyContainer>
      <div
        style={{
          zIndex: 9,
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        {value === 0 ? (
          <EnergyValue>0.00</EnergyValue>
        ) : (
          <EnergyValue>{value}</EnergyValue>
        )}
        <Unit />
      </div>
    </CircleEnergyContainer>
  );
};

export { BuildingPerformance, CircleEnergyPerformance };
