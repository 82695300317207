import { useContext, useEffect, useState } from "react";
import RoomSectionHeader from "./RoomSection/RoomSectionHeader";
import RoomSectionBody from "./RoomSection/RoomSectionBody";
import SummarySection from "./SummarySection";
import { PageContainer } from "components/Common/components";
import { FirebaseContext } from "../../firebase";
import {
  energy_saving_path,
  indoor_path,
  iot_devices,
  outdoor_path,
  EmptyDeviceData,
} from "MockData/FirebasePath";
import {
  getEnergyConsumption,
  getEnergyConsumptionByFloor,
  getRoomsByFloor,
} from "services/api.service";
import { Col, Row } from "antd";
import {
  convertDatetimeToStrDate,
  convertDatetimeToStrMonth,
} from "utils/convertDatetime";
import { responseErrorHandler } from "services/prepareAxios";
import { prepBarGraph, prepLineGraph } from "utils/prepGraphData";
import { useBuildingContext } from "layouts/BuildingProvider";
import real_time_power_map_by_building from "configs/real_time_power_map_by_building.json";
import real_time_power_map_by_floor from "configs/real_time_power_map_by_floor.json";
import building_config from "configs/building_config.json";
import energy_consumption_key from "configs/energy_consumption_key.json";
import { mapArrayToKey } from "utils/mapArrayValueToKey";
import { useDisclosure } from "utils/hooks";
import { FloorSelection } from "./FloorSelection/FloorSelection";
import { getDeviceByFloor } from "services/api.service";
import { DeviceListData, DeviceProps } from "MockData/DeviceData";

const realtimePowerMapByBuilding: any = real_time_power_map_by_building;
const realtimePowerMapByFloor: {
  [buildingCode: string]: {
    [floorName: string]: { key: string; x: string; y: string[] };
  };
} = real_time_power_map_by_floor;
const floorByBuilding: {
  [buildingCode: string]: { floors: { label: string; value: string }[] };
} = building_config;
const energyConsumptionMap: any = energy_consumption_key;
const emptyRealtimePowerConsumtpion = {
  realtimePowerConsumption: undefined,
  dailyEnergyConsumption: undefined,
  monthlyEnergyConsumption: undefined,
  co2: undefined,
};

const FloorUsagePage = () => {
  const { buildingCode, buildingName } = useBuildingContext();
  const viewGrapDialog = useDisclosure();
  const [deviceList, setDeviceList] = useState<any>([]);
  const [visibleDeviceObject, setVisibleDeviceObject] = useState<any>();
  const firebase = useContext<any>(FirebaseContext);
  const [outdoorData, setOutdoorData] = useState();
  const [indoorData, setIndoorData] = useState();
  const [energySavingData, setEnergySavingData] = useState({
    this_month: "",
    accumulated: "",
  });
  const [iotDevices, setIotDevices] = useState(EmptyDeviceData);
  const [realtimePowerData, setRealtimePowerData] = useState<any>();
  const [realtimePowerConsumption, setRealtimePowerConsumption] = useState<any>(
    realtimePowerMapByFloor &&
      Object.keys(realtimePowerMapByFloor[buildingCode]).reduce(
        (acc: any, curr: string) => ({
          ...acc,
          [curr]: emptyRealtimePowerConsumtpion,
        }),
        {}
      )
  );
  const [selectedFloorName, setSelectedFloorName] = useState("F1");
  const [floorMap, setFloorMap] = useState<{ [key: string]: any }>(
    mapArrayToKey(floorByBuilding[buildingCode]["floors"], "label")
  );
  const [starttime] = useState(new Date());
  const [endtime] = useState(new Date());
  const [selectedView, setSelectedView] = useState("fp");
  const [roomsData, setRoomsData] = useState<{ [floorName: string]: any }>({});
  // Convert array of device type (DeviceListData) to object of device type
  const [selectedDeviceList, setSelectedDeviceList] = useState<{
    [device_agent: string]: any;
  }>(mapArrayToKey(DeviceListData, "agentId"));

  //---------------------------1) MAIN---------------------------//
  function fetchFbData(didMount: boolean) {
    if (didMount) {
      firebase.db.ref(outdoor_path).off("value");
      firebase.db.ref(indoor_path).off("value");
      firebase.db.ref(iot_devices).off("value");
      firebase.db.ref(energy_saving_path).off("value");
    } else {
      firebase.db
        .ref(outdoor_path)
        .on("value", function (snap: { val: () => any }) {
          if (snap) {
            setOutdoorData(snap.val());
          }
        });
      firebase.db
        .ref(indoor_path)
        .on("value", function (snap: { val: () => any }) {
          if (snap) {
            setIndoorData(snap.val());
          }
        });
      firebase.db
        .ref(iot_devices)
        .on("value", function (snap: { val: () => any }) {
          if (snap) {
            setIotDevices(snap.val());
          }
        });
      firebase.db
        .ref(energy_saving_path)
        .on("value", function (snap: { val: () => any }) {
          if (snap) {
            setEnergySavingData(snap.val());
          }
        });
    }
  }

  const fetchEnergyConsumptionBuilding = async (buildingCode: string) => {
    let today = new Date();
    let tempStarttime = convertDatetimeToStrDate(today);
    let tempEndtime = convertDatetimeToStrDate(today, "tomorrow");
    try {
      const response = await getEnergyConsumption({
        type: "dashboard",
        building_name: buildingName,
        starttime: tempStarttime,
        endtime: tempEndtime,
      });
      let result = response.data.results;
      // 1) Set summary graph
      setRealtimePowerData(
        prepLineGraph(
          result.building_power_kW,
          realtimePowerMapByBuilding[buildingCode],
          "timestamp"
        )
      );
      // 2) Set modal graph
      Object.entries(realtimePowerMapByFloor[buildingCode]).forEach(
        ([floorName, val]: any) => {
          setRealtimePowerConsumption((prev: any) => {
            const temp = { ...prev };
            if (!temp?.[floorName]?.["realtimePowerConsumption"]) {
              temp[floorName]["realtimePowerConsumption"] = prepLineGraph(
                result[val.key],
                val.y,
                val.x
              );
            }
            return temp;
          });
        }
      );
    } catch (err) {
      console.log(err);
      setRealtimePowerData(null);
      setRealtimePowerConsumption((prev: any) => {
        return { ...prev, [selectedFloorName]: null };
      });
      responseErrorHandler(err);
    }
  };

  const fetchRoomsData = async (floorName: string) => {
    const floorValue = floorMap[floorName]["value"];
    if (roomsData[floorName]) return;
    const result = await getRoomsByFloor({ floor_name: floorValue });
    setRoomsData((prev) => ({ ...prev, [floorName]: result.data.rooms }));
  };

  useEffect(() => {
    setFloorMap(
      mapArrayToKey(floorByBuilding[buildingCode]["floors"], "label")
    );
    fetchEnergyConsumptionBuilding(buildingCode);
    fetchFbData(false);
    return () => {
      fetchFbData(true);
    };
    // eslint-disable-next-line
  }, []);
  //-------------------------END MAIN--------------------------//

  //---------------------------2) FLOOR---------------------------//
  const fetchEnergyConsByFloor = async (selectedFloorName: string) => {
    try {
      const dailyRequest = getEnergyConsumptionByFloor({
        type: "floor_energy",
        building_name: buildingName,
        starttime: convertDatetimeToStrDate(starttime, -6),
        endtime: convertDatetimeToStrDate(endtime),
        floor_name: floorMap[selectedFloorName]["value"],
        sample: "daily",
      });
      const monthlyRequest = getEnergyConsumptionByFloor({
        type: "floor_energy",
        building_name: buildingName,
        starttime: convertDatetimeToStrMonth(starttime, -2),
        endtime: convertDatetimeToStrMonth(endtime),
        floor_name: floorMap[selectedFloorName]["value"],
        sample: "monthly",
      });
      const [dailyResponse, monthlyResponse] = await Promise.all([
        dailyRequest,
        monthlyRequest,
      ]);
      let dailyResult = dailyResponse?.data?.floor_data;
      let monthlyResult = monthlyResponse?.data?.floor_data;
      if (
        realtimePowerConsumption &&
        realtimePowerConsumption[selectedFloorName] &&
        dailyResult &&
        monthlyResult
      ) {
        setRealtimePowerConsumption((prev: any) => {
          const tempPrev: any = { ...prev };
          const dailyResultIndex = dailyResult.length - 1;
          const monthlyResultIndex = monthlyResult.length - 1;
          const energyKeyList =
            energyConsumptionMap?.[selectedFloorName]?.["kWh"];
          const co2KeyList = energyConsumptionMap?.[selectedFloorName]?.["co2"];
          if (tempPrev[selectedFloorName]) {
            tempPrev[selectedFloorName]["dailyEnergyConsumption"] =
              prepBarGraph(dailyResult, energyKeyList, "date");
            tempPrev[selectedFloorName]["monthlyEnergyConsumption"] =
              prepBarGraph(monthlyResult, energyKeyList, "date");
            tempPrev[selectedFloorName]["co2"] = {
              daily: dailyResult[dailyResultIndex]?.[co2KeyList],
              monthly: monthlyResult[monthlyResultIndex]?.[co2KeyList],
            };
          }
          return tempPrev;
        });
      }
    } catch (err) {
      responseErrorHandler(err);
      setRealtimePowerConsumption({
        [selectedFloorName]: {
          realtimePowerConsumption: null,
          dailyEnergyConsumption: null,
          monthlyEnergyConsumption: null,
          co2: {
            daily: null,
            monthly: null,
          },
        },
      });
    }
  };

  const fetchAllDevices = async (floor: string) => {
    try {
      setDeviceList([]);
      let listOfDeviceRes: any[] = [];
      const results = await getDeviceByFloor(floorMap[floor]["floor_id"]);
      listOfDeviceRes = results.data["floor_info"][0]["devices"].map(
        (item: any) => ({
          ...item,
          ui_location: item.ui_location ? item.ui_location.split(",") : [0, 0],
        })
      );
      setDeviceList(listOfDeviceRes);
      let visibleDevice: any = {};
      listOfDeviceRes.forEach((deviceObject: any) => {
        visibleDevice[deviceObject.agent_id] = true;
      });
      setVisibleDeviceObject(visibleDevice);
    } catch (error) {
      console.log(error);
      responseErrorHandler(error);
    }
  };

  const handleSelectFloor = (floorName: string) => {
    setSelectedFloorName(floorName);
  };

  useEffect(() => {
    fetchAllDevices(selectedFloorName);
    fetchEnergyConsByFloor(selectedFloorName);
    fetchRoomsData(selectedFloorName);
  }, [selectedFloorName]);
  //------------------------END FLOOR------------------------//

  return (
    <>
      <PageContainer>
        <Row gutter={[16, 16]}>
          <Col xs={2}>
            <FloorSelection
              selectedTab={selectedFloorName}
              setSelectedTab={handleSelectFloor}
              buildingCode={buildingCode}
            />
          </Col>
          <Col xs={22} style={{ zIndex: 9999 }}>
            <Row gutter={[16, 16]}>
              <Col xs={24}>
                <RoomSectionHeader
                  setVisibleDeviceObject={setVisibleDeviceObject}
                  selectedTab={selectedFloorName}
                  buildingName={buildingName}
                  selectedView={selectedView}
                  setSelectedView={setSelectedView}
                  deviceList={deviceList}
                  selectedDeviceList={selectedDeviceList}
                  setSelectedDeviceList={setSelectedDeviceList}
                />
              </Col>
              <Col xs={24}>
                <RoomSectionBody
                  deviceList={deviceList.filter(
                    (device: DeviceProps) =>
                      selectedDeviceList?.[device.agent_id]?.isOpened
                  )}
                  selectedTab={selectedFloorName}
                  iotDevices={iotDevices}
                  outdoorData={outdoorData}
                  indoorData={indoorData}
                  visibleDeviceObject={visibleDeviceObject}
                  buildingName={buildingName}
                  buildingCode={buildingCode}
                  selectedView={selectedView}
                  roomsData={roomsData?.[selectedFloorName]}
                  onOpenViewGraph={viewGrapDialog.onOpen}
                />
              </Col>
              {selectedView === "fp" &&
                buildingCode !== "SHE" &&
                buildingCode !== "LTLS" &&
                buildingCode !== "DAIR" && (
                  <Col xs={24}>
                    <SummarySection
                      realtimePowerData={realtimePowerData}
                      realtimePowerConsumption={realtimePowerConsumption}
                      selectedTab={selectedFloorName}
                      energySaving={energySavingData}
                      floorMap={floorMap}
                    />
                  </Col>
                )}
            </Row>
          </Col>
        </Row>
      </PageContainer>
    </>
  );
};

export default FloorUsagePage;
